import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const BenefitsStyle = {
  wrapper: css`
    margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 175px);

    gap: 16px;

    ${media.xxl} {
      grid-template-columns: repeat(3, 1fr);
    }
    ${media.lg} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${media.sm} {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, 175px);
    }
  `,
  index: css`
    position: relative;

    .image-bg {
      border-radius: 12px;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  title: css`
    font-size: 22px;
    font-weight: 600;

    margin-top: 40px;
  `,
  image: css`
    position: absolute;
    bottom: 0;
    left: 0;

    height: 100%;
    width: 100%;
    img {
      max-width: 100%;
      max-height: 80%;
      object-fit: contain;
      position: absolute;
      bottom: 0;
      left: 10px;
    }
  `,
  textBox: css`
    position: absolute;
    top: 0;
    right: 20px;

    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  `,

  textBoxHeader: css`
    font-size: 0.9375rem;
    font-weight: 500;

    border-bottom: 0.5px solid ${theme.colors.neutrals[100]};
    padding-bottom: 15px;
  `,
  textBoxBottom: css`
    margin-top: 15px;
    font-size: 20px;
    font-weight: 650;
    color: white;
    text-align: right;
  `,
};
