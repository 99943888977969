import TF from "assets/images/game/e-sports/esports_TF.png";
import { SubGameType } from "types/game";

export const listGameEsports: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "LH",
    type: "ES",
    featured: "NO",
    new_arrive: "NO",
    picture: TF,
  },
];
