import { createAsyncThunk } from "@reduxjs/toolkit";
import { promotionsApi } from "api/promotions";

export const getPromotionsList = createAsyncThunk(
  "",
  async (_, { rejectWithValue }) => {
    try {
      const response = await promotionsApi.promotionsList();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
