import { PayloadAction } from "@reduxjs/toolkit";
import { GameStateType } from "types/game";

import { initialState } from ".";

export const reducers = {
  resetDataCategoryGameFilter: (state: GameStateType) => {
    state.dataCategoryGameFilter = initialState.dataCategoryGameFilter;
  },
  setCategoryCurrent: (state: GameStateType, action: PayloadAction<string>) => {
    state.categoryCurrent = action.payload;
  },
  resetDataSubGame: (state: GameStateType) => {
    state.dataSubGame = { ...initialState.dataSubGame };
  },
};
