/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button } from "antd";

interface Props {
  value: number;
  onClick(): void;
  selectValuePercentage: number;
}

export const ButtonAmout = (props: Props) => {
  const { onClick, value, selectValuePercentage } = props;
  return (
    <Button
      css={css`
        color: white;
        background-color: #3c4048;
        border: ${selectValuePercentage === value
          ? " 1px solid white "
          : " 1px solid transparent"};
        border-radius: 10px;
        height: 40px;
        font-weight: 200;
        transition: all 0.3s ease;

        &:hover {
          color: white !important;
          border: 1px solid white !important;
        }
      `}
      onClick={onClick}
    >
      {value}%
    </Button>
  );
};
