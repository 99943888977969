/** @jsxImportSource @emotion/react */

import { useTranslation } from "react-i18next";

import { TypeListCollection } from "../ListProvider/type";
import { CurrencyItemProfileStyle } from "./style.index";

const CurrencyItemProfile = (props: TypeListCollection) => {
  const { data, image, name } = props;

  const { t } = useTranslation("profile");
  return (
    <div css={CurrencyItemProfileStyle.currencyItem.index}>
      <div css={CurrencyItemProfileStyle.currencyItem.header}>
        <img src={image} alt="" />
        <span>{t(`${name}`)}</span>
      </div>
      <div css={CurrencyItemProfileStyle.currencyItem.bottom}>
        <span>{data}</span>
      </div>
    </div>
  );
};

export default CurrencyItemProfile;
