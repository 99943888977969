/** @jsxImportSource @emotion/react */

import { arrayProvider } from "contants/provider";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FooterStyle } from "./index.style";

const Footer = () => {
  const { t } = useTranslation("footer");
  return (
    <>
      <div css={FooterStyle.container}>
        <div css={FooterStyle.footerLeft}>
          <div css={FooterStyle.groupBox}>
            <h3>GX Gaming</h3>
            <Link to={"#"}>{t("DownloadAPP")}</Link>
            <Link to={"#"}>{t("About")}</Link>
          </div>
          <div style={{ marginTop: "16px" }} css={FooterStyle.groupBox}>
            <h3>{t("TermsPolicies")}</h3>
            <Link to={"#"}>{t("policy")}</Link>
            <Link to={"#"}>{t("PrivacyPolicy")}</Link>
            <Link to={"#"}>{t("UserAgreement")}</Link>
            <Link to={"#"}>{t("TermsService")}</Link>
          </div>
        </div>
        <div css={FooterStyle.footerRight}>
          <div css={FooterStyle.provider}>
            <h3>{t("Provider")}</h3>
            <div className="list-provider">
              {arrayProvider.map((item, index) => {
                return (
                  <Link
                    key={`provider-item-${index}`}
                    to={item.link}
                    className="item-provider"
                  >
                    <img src={item.icon} alt="" />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div css={FooterStyle.copyRight}>
          <h3>
            {t("COPYRIGHT")} © 2023 GX Gaming {t("AllReserved")}
          </h3>
        </div>
      </div>
    </>
  );
};

export default Footer;
