import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "api/auth/type";
import { ResponseText } from "contants/response";
import { NoInfer } from "react-redux";
import { ApiResponse } from "types/api";
import { AuthStateType } from "types/auth";

import { registerMemberDetail, signInWithCredentials } from "./auth.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<AuthStateType>>
) => {
  builder
    .addCase(signInWithCredentials.pending, (state: AuthStateType) => {
      state.isLoading = true;
    })
    .addCase(
      signInWithCredentials.fulfilled,
      (state: AuthStateType, action: PayloadAction<LoginResponse>) => {
        state.isLoading = false;

        if (action.payload.msg !== ResponseText.SUCCESS) {
          state.messageRes =
            "Incorrect login, username, phone number or password";
          return;
        }

        const { username, token } = action.payload;

        localStorage.setItem("token", token);
        localStorage.setItem("username", username);
        state.username = username;
        state.token = token;
        state.messageRes = "";
        state.isAuthenticated = true;
      }
    )
    .addCase(signInWithCredentials.rejected, (state: AuthStateType) => {
      state.isLoading = false;
    });
  builder
    .addCase(registerMemberDetail.pending, (state: AuthStateType) => {
      state.isLoading = true;
    })
    .addCase(
      registerMemberDetail.fulfilled,
      (state: AuthStateType, action: PayloadAction<ApiResponse>) => {
        state.isLoading = false;
      }
    )
    .addCase(registerMemberDetail.rejected, (state: AuthStateType) => {
      state.isLoading = false;
    });
};
