import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { maxWidthDevice } from "enums/device";
import { ApplicationRootState } from "types";
import { ModalType, ModalTypeValue } from "types/modal";

export const initialState: ModalType = {
  openModal: ModalTypeValue.Close,
  openSidebar: window.innerWidth > maxWidthDevice.XXL ? true : false,
};

const modalSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOpenModal: (state: ModalType, action: PayloadAction<ModalTypeValue>) => {
      state.openModal = action.payload;
    },
    setOpenSidebar: (state: ModalType, action: PayloadAction<boolean>) => {
      state.openSidebar = action.payload;
    },
  },
});
export const selectOpenModal = (state: ApplicationRootState) =>
  state.modal.openModal;
export const selectOpenSidebar = (state: ApplicationRootState) =>
  state.modal.openSidebar;
export const { setOpenModal, setOpenSidebar } = modalSlice.actions;
export default modalSlice.reducer;
