export enum ModalTypeValue {
  Login = "Login",
  Register = "Register",
  ForgetPassword = "ForgetPassword",
  ChangePassword = "change-password",
  Bank = "Bank",
  Close = "",
}
export interface ModalType {
  openModal: ModalTypeValue;
  openSidebar: boolean;
}
