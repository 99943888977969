import DS88 from "assets/images/game/sabong/sabong_DS88.png";
import { SubGameType } from "types/game";

export const listGameSabong: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "DS88",
    type: "CF",
    featured: "NO",
    new_arrive: "NO",
    picture: DS88,
  },
];
