import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const ItemSelectStyle = {
  container: css`
    align-items: center;
    background-color: ${theme.colors.primary[700]};
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;
    width: 100%;

    ${media.lg} {
      padding: 0 2rem;
    }

    ${media.md} {
      padding: 0.25rem 0.9375rem;
    }
  `,
  blockItem: (isSelect: boolean) => css`
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 5px;

    border: 1px solid ${isSelect ? theme.colors.secondary[900] : "transparent"};

    padding: 4px 16px;
    border-radius: 20px;
    background: ${isSelect
      ? "rgba(61, 70, 104, 0.1)"
      : "rgba(61, 70, 104, 0.3)"};
    transition: 0.25s;
    &:hover {
      background: rgba(61, 70, 104, 0.6);
      transition: 0.25s;
    }
    span {
      color: ${isSelect ? theme.colors.secondary[900] : "white"};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-transform: uppercase;
    }
  `,
};
