import { NavigateFunction } from "react-router-dom";
import { ThunkActionDispatch } from "redux-thunk";
import { resetAuth } from "store/auth";
import { resetBank } from "store/bank";
import { resetDeposit } from "store/deposit";
import { resetUser } from "store/user";

export const handleResetData = (
  dispatch: ThunkActionDispatch<any>,
  navigate: NavigateFunction
) => {
  dispatch(resetAuth());
  dispatch(resetUser());
  dispatch(resetDeposit());
  dispatch(resetBank());
  localStorage.clear();
  navigate("/");
};
