import { createAsyncThunk } from "@reduxjs/toolkit";
import { BannerApi } from "api/banner";

export const getListBanner = createAsyncThunk(
  "banner/getListBanner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await BannerApi.getBanner();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
