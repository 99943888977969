/** @jsxImportSource @emotion/react */

import { LIST_MY_BENEFITS } from "contants/my-benefits";
import { useAppSelector } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { selectDataMemberVip } from "store/member";

import { BenefitsStyle } from "./style.index";

const Benefits = () => {
  const { t } = useTranslation("vipDetails");
  const { data: dataBenefits } = useAppSelector(selectDataMemberVip);
  const data = Object.keys(dataBenefits.benefit);
  return (
    <>
      <div css={BenefitsStyle.title}>{t("MyBenefit")}</div>
      <div css={BenefitsStyle.wrapper}>
        {LIST_MY_BENEFITS.map((item) => (
          <div key={item.key} css={BenefitsStyle.index}>
            <img className="image-bg" src={item.imageBg} alt="" />
            <div css={BenefitsStyle.image}>
              <img src={item.image} alt="" />
            </div>
            <div css={BenefitsStyle.textBox}>
              <div css={BenefitsStyle.textBoxHeader}>{t(`${item.title}`)}</div>
              <div css={BenefitsStyle.textBoxBottom}>
                <>
                  {item.key === data[0]
                    ? dataBenefits.benefit.birthday_bonus
                    : dataBenefits.benefit.upgrade_reward}{" "}
                  $
                </>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Benefits;
