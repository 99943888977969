import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { DepositFormSettingResponse } from "api/deposit/type";
import { NoInfer } from "react-redux";
import { DepositStateType } from "types/deposit";

import { getDepositForm } from "./deposit.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<DepositStateType>>
) => {
  builder
    .addCase(getDepositForm.pending, (state: DepositStateType) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(
      getDepositForm.fulfilled,
      (
        state: DepositStateType,
        action: PayloadAction<DepositFormSettingResponse>
      ) => {
        // action.payload.data.setting.min_deposit = Number(
        //   action.payload.data.setting.min_deposit
        // );
        // action.payload.data.setting.max_deposit = Number(
        //   action.payload.data.setting.max_deposit
        // );
        return {
          ...state,
          isLoading: false,
          dataDeposit: action.payload.data,
        };
      }
    );
};
