/** @jsxImportSource @emotion/react */

import { DownOutlined, SyncOutlined } from "@ant-design/icons";
import { Image } from "antd";
import PesoIcon from "assets/images/peso.png";
import PopupCustom, { RefTypePopup } from "components/ui/Header/PopupCustom";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useRef } from "react";
import { selectUsername } from "store/auth";
import { selectBalance, selectBalanceLoading } from "store/user";
import { getMemberBalance } from "store/user/user.thunk";

import { WalletDropdownStyle } from "./index.style";

function WalletDropdown() {
  const dispatch = useAppDispatch();

  const popupRef = useRef<RefTypePopup>(null);

  const username = useAppSelector(selectUsername);
  const memberBalance = useAppSelector(selectBalance);
  const isLoading = useAppSelector(selectBalanceLoading);

  const dataCurrency = [
    {
      name: "Peso",
      icon: PesoIcon,
      amount: memberBalance?.total,
    },
  ];

  return (
    <>
      <PopupCustom
        ref={popupRef}
        top={"60px"}
        buttonContent={
          <div css={WalletDropdownStyle.container}>
            <div
              onClick={() => {
                popupRef.current?.onToggle();
              }}
              css={WalletDropdownStyle.value}
            >
              <Image src={PesoIcon} height={24} width={24} preview={false} />
              <span>{memberBalance?.total}</span>
              <DownOutlined className="iconProps" />
            </div>
            <div
              css={[
                WalletDropdownStyle.refresh,
                isLoading && WalletDropdownStyle.loading,
              ]}
            >
              <span onClick={() => dispatch(getMemberBalance({ username }))}>
                <SyncOutlined />
              </span>
            </div>
          </div>
        }
        popupContent={
          <div css={WalletDropdownStyle.contentPopup}>
            <h3>Switch wallet</h3>
            <div className="list-currency">
              {dataCurrency.map((item, index) => {
                return (
                  <div
                    onClick={() => popupRef.current?.onToggle()}
                    key={`item-currency-${index}`}
                    className="item-currency"
                  >
                    <Image
                      src={item.icon}
                      height={24}
                      width={24}
                      preview={false}
                    />
                    <span className="name">{item.name}</span>
                    <span className="amount">{item.amount}</span>
                  </div>
                );
              })}
            </div>
          </div>
        }
      />
    </>
  );
}

export default WalletDropdown;
