import { SubGameType } from "types/game";

import { listGameCrashGame } from "./list-game_crash-game";
import { listGameEsports } from "./list-game_e-sport";
import { listGameFishing } from "./list-game_fishing";
import { listGameLiveCasino } from "./list-game_live-casino";
import { listGameSabong } from "./list-game_sabong";
import { listGameSlots } from "./list-game_slots";
import { listGameSports } from "./list-game_sports";
import { listGameTableCard } from "./list-game_table-card";

export interface AllCategoryGameType {
  id: string;
  data: SubGameType[];
  pathname: string;
  label: string;
}

export const allCategoryGame: AllCategoryGameType[] = [
  {
    id: "LC",
    pathname: "/live",
    data: listGameLiveCasino,
    label: "sidebar:Live",
  },
  {
    id: "SL",
    pathname: "/slots",
    data: listGameSlots,
    label: "sidebar:Slots",
  },
  {
    id: "SB",
    pathname: "/sports",
    data: listGameSports,
    label: "sidebar:Sports",
  },
  {
    id: "ES",
    pathname: "/e-sport",
    data: listGameEsports,
    label: "sidebar:ESports",
  },
  {
    id: "FH",
    pathname: "/fishing",
    data: listGameFishing,
    label: "sidebar:Fishing",
  },
  {
    id: "BG",
    pathname: "/crash-game",
    data: listGameCrashGame,
    label: "sidebar:Crash",
  },
  {
    id: "CF",
    pathname: "/sabong",
    data: listGameSabong,
    label: "sidebar:Sabong",
  },
  {
    id: "BG",
    pathname: "/table-card",
    data: listGameTableCard,
    label: "sidebar:Table",
  },
];
