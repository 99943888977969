/** @jsxImportSource @emotion/react */

import { showStatusText } from "pages/PlayRecordPage/showStatusText";

import { BettingRecordCardStyle } from "./index.style";

interface BettingRecordCardProps {
  game: string;
  provider: string;
  date: string;
  stake: string;
  winlose: string;
}

const BettingRecordCard = (props: BettingRecordCardProps) => {
  const { game, provider, date, stake, winlose } = props;

  return (
    <div css={BettingRecordCardStyle.self}>
      <div css={BettingRecordCardStyle.cardLeft}>
        <div css={BettingRecordCardStyle.title}>{game}</div>
        <div css={BettingRecordCardStyle.title}>{provider}</div>
        <div css={BettingRecordCardStyle.createdDate}>{date}</div>
      </div>
      <div css={BettingRecordCardStyle.cardRight}>
        <div css={BettingRecordCardStyle.amount}>+ {stake}</div>
        <div css={BettingRecordCardStyle.status(Number(winlose))}>
          {`${showStatusText(Number(winlose))} ${winlose}`}
        </div>
      </div>
    </div>
  );
};

export default BettingRecordCard;
