import { css } from "@emotion/react";

export const MenuStyle = {
  container: css`
    width: 100%;
    height: auto;
  `,
  listMenu: css`
    display: flex;
    flex-direction: column;
  `,
};
