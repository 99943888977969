import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { listMenuSideBar } from "contants/list-menu";
import { ApplicationRootState } from "types";
import { masterStateType, MenuItemType } from "types/master";

export const initialState: masterStateType = {
  clientCountryCode: "",
  listSideBar: listMenuSideBar,
};

const master = createSlice({
  name: "master",
  initialState,
  reducers: {
    setClientCountryCode(
      state: masterStateType,
      action: PayloadAction<string>
    ) {
      localStorage.setItem("i18nLng", action.payload);
      state.clientCountryCode = action.payload;
    },

    setListMenuSidebar(
      state: masterStateType,
      action: PayloadAction<MenuItemType[]>
    ) {
      state.listSideBar = action.payload;
    },
  },
});

export const selectClientCountryCode = (state: ApplicationRootState) =>
  state.master.clientCountryCode;

export const setlectListMenuSidebar = (state: ApplicationRootState) =>
  state.master.listSideBar;

export const { setClientCountryCode, setListMenuSidebar } = master.actions;

export default master.reducer;
