/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button } from "antd";
import IconLanguage from "assets/icons/menus/imageIconLanguage.png";
import IconLanguageZH from "assets/icons/menus/imageIconLanguage-ChINESE.png";
import IconLanguageVN from "assets/icons/menus/imageIconLanguage-VIETNAM.png";
import { ReactComponent as MenuSvg } from "assets/icons/menus/menuLeftArrow.svg";
import { useModalConfirm } from "components/base/ModalConfirm";
import { ContactUsData, listMenuSideBar } from "contants/list-menu";
import { maxWidthDevice } from "enums/device";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useAppLogout } from "hooks/useLogout";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { selectIsAuthenticated } from "store/auth";
// import { selectContacts } from "store/contact";
// import { getContactList } from "store/contact/contact.thunk";
import { resetDataCategoryGameFilter } from "store/game";
import {
  selectClientCountryCode,
  setlectListMenuSidebar,
  setListMenuSidebar,
} from "store/master";
import { selectOpenSidebar, setOpenSidebar } from "store/modal";
import { theme } from "styles/theme";
import { MenuItemType } from "types/master";

import Menu from "../Menu";
import HeaderMedium from "./HeaderMedium";
import { SideBarStyles } from "./index.style";
const SideBar = () => {
  const { t } = useTranslation("sidebar");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { width } = useWindowSize();
  const { contextHolder, modalConfig } = useModalConfirm();
  const onLogout = useAppLogout();

  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const isSideBar = useAppSelector(selectOpenSidebar);
  const clientContryCode = useAppSelector(selectClientCountryCode);
  const listSideBar: MenuItemType[] = useAppSelector(setlectListMenuSidebar);
  // const listDataContact = useAppSelector(selectContacts);

  useEffect(() => {
    const label =
      clientContryCode === "vi"
        ? "Việt Nam"
        : clientContryCode === "en"
        ? "English"
        : "中文";

    const icon =
      clientContryCode === "vi" ? (
        <img
          src={IconLanguageVN}
          css={css`
            width: 20px;
            height: 20px;
          `}
          alt=""
        />
      ) : clientContryCode === "en" ? (
        <img
          src={IconLanguage}
          css={css`
            width: 20px;
            height: 20px;
          `}
          alt=""
        />
      ) : (
        <img
          src={IconLanguageZH}
          css={css`
            width: 20px;
            height: 20px;
          `}
          alt=""
        />
      );

    const list = listMenuSideBar.map((itemMap, index) => {
      if (itemMap.isLang) {
        return {
          ...itemMap,
          ...{
            key: clientContryCode,
            label: label,
            icon: icon,
          },
        };
      }
      return itemMap;
    });
    dispatch(setListMenuSidebar(list));
  }, [clientContryCode]);

  const [idHover, setIdHover] = useState<string>("");

  const setIsSidebar = (val: boolean) => {
    dispatch(setOpenSidebar(val));
  };

  // useEffect(() => {
  //   if (listDataContact.length > 0) return;

  //   dispatch(getContactList());
  // }, [listDataContact.length]);

  const handleLogout = () => {
    modalConfig({
      title: "Do you want to logout?",
      onSuccess: () => {
        onLogout();
      },
    });
  };

  const handleContactUs = () => {
    return listSideBar.map((item, index) => {
      if (index === listMenuSideBar.length - 1) {
        // const data: MenuItem[] = [];
        // listDataContact.forEach((i) => {
        //   const valueItem = ContactUsData.find((e) => i.id === e.id);
        //   if (valueItem) {
        //     data.push({ ...valueItem, key: i.URL, isClick: true });
        //   }
        // });
        return {
          ...item,
          children: ContactUsData,
        };
      }
      return item;
    });
  };

  return (
    <>
      <div
        onMouseLeave={() => setIdHover("")}
        id={"sidebar-menu"}
        css={SideBarStyles.container(isSideBar)}
      >
        <div css={SideBarStyles.main}>
          <div
            onClick={() => setIsSidebar(!isSideBar)}
            css={SideBarStyles.btnSlideBar(isSideBar)}
          >
            <MenuSvg fill={theme.colors.white} />
          </div>
          <HeaderMedium setIsSidebar={setIsSidebar} />
          <div css={SideBarStyles.content(isSideBar)}>
            <Menu
              onClick={(e) => {
                if (e.id) {
                  window.open(e.key);
                  return;
                }
                navigate(`${e?.key}`);
                dispatch(resetDataCategoryGameFilter());
                if (width <= maxWidthDevice.MD) {
                  setIsSidebar(false);
                }
              }}
              items={handleContactUs()}
              itemKey={pathname}
              order={0}
              idHover={idHover}
              onMouseEnter={(id: string) => setIdHover(id)}
              isSideBar={isSideBar}
            />
            {isAuth && (
              <div css={SideBarStyles.logoutBox}>
                <Button onClick={handleLogout}>
                  <span>{t("Logout")}</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isSideBar && (
        <div onClick={() => setIsSidebar(false)} css={SideBarStyles.overlay} />
      )}
      {contextHolder}
    </>
  );
};

export default SideBar;
