import dayjs from "dayjs";

export const listCalendar = [
  { id: "today", value: "record:today", startDate: dayjs(), endDate: dayjs() },
  {
    id: "3day",
    value: "record:3day",
    startDate: dayjs().subtract(3, "days"),
    endDate: dayjs(),
  },
  {
    id: "7day",
    value: "record:week",
    startDate: dayjs().startOf("weeks"),
    endDate: dayjs(),
  },
  {
    id: "month",
    value: "record:month",
    startDate: dayjs().startOf("months"),
    endDate: dayjs(),
  },
];
