/** @jsxImportSource @emotion/react */
import backgroundImage from "assets/images/bg-main.jpg";
import backgroundMyCollection from "assets/images/bg-my-collection.png";
import backgroundPlayRecord from "assets/images/bgPlayRecord.png";
import Modal from "components/base/Modal";
import BottomTab from "components/ui/BottomTab";
import Footer from "components/ui/Footer";
import Header from "components/ui/Header";
import SideBar from "components/ui/SideBar";
import PATH from "contants/path";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { outletContextType } from "types";

import { DefaultLayoutStyles } from "./index.style";
const DefaultLayout = () => {
  const { openNotification } = useOutletContext<outletContextType>();
  const { pathname } = useLocation();

  const isPlayRecord = pathname.includes(PATH.playRecordPage);

  const isMyCollection = pathname.includes(PATH.myCollectionPage);

  const handleChangeBackground = () => {
    if (isPlayRecord) return backgroundPlayRecord;
    if (isMyCollection) return backgroundMyCollection;
    return backgroundImage;
  };

  const background = handleChangeBackground();

  return (
    <div css={DefaultLayoutStyles.main(background)}>
      <SideBar />
      <main css={DefaultLayoutStyles.appContent}>
        <Header />
        <div id={"page-content"} css={DefaultLayoutStyles.pageContent}>
          <Outlet context={{ openNotification } as outletContextType} />
          {pathname === "/" && <Footer />}
        </div>
      </main>
      <BottomTab />
      <Modal />
    </div>
  );
};

export default DefaultLayout;
