/** @jsxImportSource @emotion/react */
import { SearchSvg } from "contants/config";
import { theme } from "styles/theme";

import { SearchStyle } from "./style.index";

const Search = () => {
  return (
    <div css={SearchStyle.wrapper}>
      <form css={SearchStyle.form}>
        <SearchSvg fill={theme.colors.white} />
        <input type="text" placeholder="Search Game" />
      </form>
    </div>
  );
};

export default Search;
