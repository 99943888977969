/** @jsxImportSource @emotion/react */

import { LIST_CURRENCY } from "dummy/list-currency";
import { LIST_GAME_TYPE } from "dummy/list-game-type";
import { LIST_PROVIDERS } from "dummy/list-providers";

import ProviderItem from "./ProviderItem";
import { ListProviderStyle } from "./style.index";

const ListProvider = () => {
  return (
    <div css={ListProviderStyle.wrapper}>
      <ProviderItem data={LIST_GAME_TYPE} />
      <ProviderItem data={LIST_PROVIDERS} />
      <ProviderItem data={LIST_CURRENCY} />
      <ProviderItem data={LIST_GAME_TYPE} />
    </div>
  );
};

export default ListProvider;
