import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { ContactResponse } from "./type";

export const ContactApi = {
  async getContact() {
    const { data } = await axiosRequest.post<ContactResponse>(
      "/",
      payloadHelper("ContactUs", null, null)
    );
    return data;
  },
};
