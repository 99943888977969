import DG from "assets/images/game/LiveCasino/live-casino-DG.png";
import EVO from "assets/images/game/LiveCasino/live-casino-EVO.png";
import GX from "assets/images/game/LiveCasino/live-casino-GX.png";
import SA from "assets/images/game/LiveCasino/live-casino-SA.png";
import SX from "assets/images/game/LiveCasino/live-casino-SX.png";
import WM from "assets/images/game/LiveCasino/live-casino-WM.png";
import { SubGameType } from "types/game";

export const listGameLiveCasino: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "EVO",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: EVO,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "SX",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: SX,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "DG",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: DG,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "GX",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: GX,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "WM",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: WM,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "SA",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: SA,
  },
];
