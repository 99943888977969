import { css, keyframes } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";
const spin = keyframes`
  0% {
    transform: rotate(0deg) translate3d(3px);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const BottomTabStyles = {
  container: css`
    align-items: center;
    background-color: ${theme.colors.primary[900]};
    border-top: 1px solid #313c50;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    padding: 0.75rem 0;
    width: 100vw;
    display: none;
    overflow: hidden;
    min-width: 325px;
    position: fixed;
    bottom: 0;
    z-index: 10;
    ${media.md} {
      display: block;
    }
  `,
  listTab: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  item: css`
    display: flex;
    padding: 0rem 0.64188rem 0rem 0.63938rem;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;
    text-decoration: none;
  `,
  icon: () => css`
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  spinAnimated: css`
    animation: ${spin} 3s linear infinite;
  `,

  name: (isActive: boolean) => css`
    color: ${isActive ? theme.colors.secondary[900] : theme.colors.white};
    font-size: 0.8125rem;
    line-height: 1rem;
  `,
};
