/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { handleLoginGame } from "pages/Game/helpers/handleLoginGame";
import { selectUsername } from "store/auth";
import { media } from "styles/breakpoints";
import { SubGameType } from "types/game";

interface Props {
  subGame: SubGameType;
}

export const SubGameItem = (props: Props) => {
  const { subGame } = props;

  const dispatch = useAppDispatch();

  const username = useAppSelector(selectUsername);

  return (
    <div
      css={css`
        width: 100%;
      `}
      key={subGame.game_code}
      onClick={() => handleLoginGame(subGame, username, dispatch)}
    >
      <img
        loading="lazy"
        src={subGame.picture}
        css={css`
          width: 100%;
          height: 100%;
          border-radius: 5px;

          cursor: pointer;

          transition: 0.2s all;

          &:hover {
            scale: 1.05;
          }

          ${media.sm} {
            &:hover {
              scale: 1;
            }
          }
        `}
        alt="itemGameSub"
      />
    </div>
  );
};
