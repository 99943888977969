import { css, Theme } from "@emotion/react";
import { media } from "styles/breakpoints";

export const SubGamePageStyle = {
  wrapper: () => css`
    width: 100%;
    padding: 32px 64px;

    ${media.md} {
      padding: 0 16px;
    }

    ${media.sm} {
      padding: 0 16px;
    }
  `,

  container: () => css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,

  empty: () => css`
    height: 100% !important;
    justify-content: center !important;
    display: flex !important;
    flex-direction: column !important;
  `,

  wrapperListSubGame: () => css`
    gap: 16px;
    padding: 0 32px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    ${media.md} {
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;
      padding: 0 16px;
    }

    ${media.sm} {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 16px;
      gap: 8px;
    }
  `,

  boxPagination: () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  `,

  paginationStyle: ({ colors }: Theme) => css`
    .ant-pagination-next button {
      color: ${colors.primary[300]} !important;
      &:hover {
        border-color: ${colors.primary[300]} !important;
        background-color: ${colors.white} !important;
      }
    }
    .ant-pagination-prev button {
      color: ${colors.primary[300]} !important;
      &:hover {
        border-color: ${colors.primary[300]} !important;
        background-color: ${colors.white} !important;
      }
    }
    .ant-pagination-disabled button:disabled {
      color: ${colors.neutrals[50]} !important;
      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
      }
    }
    .ant-pagination-item {
      border-color: white;
      a {
        color: white;
      }
    }
    .ant-pagination-item-active {
      border-color: ${colors.primary[300]} !important;
    }
    .ant-pagination-item:hover {
      border-color: ${colors.primary[300]} !important;
      background-color: ${colors.white} !important;
    }
    .ant-pagination-item:hover a {
      color: ${colors.primary[300]} !important;
    }
    .ant-pagination-item-active a {
      color: ${colors.primary[300]} !important;
    }
  `,
  inputControl: css`
    margin: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 40%;

    ${media.sm} {
      padding: 0;
      margin: 8px 0;
      /* margin-bottom: 12px; */
      padding: 0 12px;
      width: 100%;
    }
  `,
  input: () => css`
    border-radius: 8px;
    line-height: 1.8;
    padding: 8px 16px;
  `,
  searchIcon: css`
    margin-right: 8px;
  `,
  blockSort: css`
    margin: 24px 32px;
    background-color: rgba(16, 21, 32, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    ${media.md} {
      padding: 16px;
    }
    ${media.sm} {
      padding: 0;
      margin: 0 24px;
      margin-bottom: 12px;
    }
  `,
};
