import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { PromotionsListStateType } from "types/promotions";

import { extraReducers } from "./promotions.extraReducers";

export const initialState: PromotionsListStateType = {
  isLoading: false,
  promotionsList: [],
};

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {},
  extraReducers,
});
export const selectLoadingPromotionsList = (state: ApplicationRootState) =>
  state.promotions.isLoading;
export const selectPromotionsList = (state: ApplicationRootState) =>
  state.promotions.promotionsList;

export default promotionsSlice.reducer;
