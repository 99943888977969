import ImageBenefit_1 from "assets/images/profile/my-benefit-1.png";
import ImageBenefit_2 from "assets/images/profile/my-benefit-2.png";
import MyBenefitsBgImage_1 from "assets/images/profile/my-benefit-bg-1.png";
import MyBenefitsBgImage_2 from "assets/images/profile/my-benefit-bg-2.png";

interface TypeMyBenefits {
  image: string;
  imageBg: string;
  title: string;
  key: string;
}

export const LIST_MY_BENEFITS: TypeMyBenefits[] = [
  {
    image: ImageBenefit_1,
    imageBg: MyBenefitsBgImage_1,
    title: "BirthDayBonus",
    key: "birthday_bonus",
  },
  {
    image: ImageBenefit_2,
    imageBg: MyBenefitsBgImage_2,
    title: "UpgradeReward",
    key: "upgrade_reward",
  },
];
