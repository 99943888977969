import { css } from "@emotion/react";

export const AmountStepStyle = {
  index: () => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  title: () => css`
    margin-bottom: 10px;
    color: #9196af;
    font-size: medium;
  `,
};
