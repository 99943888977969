import { maxWidthDevice } from "enums/device";
import useWindowSize from "hooks/useWindowSize";
import { useEffect } from "react";

function useChangeHeaderMobile() {
  const { width } = useWindowSize();
  const handleScroll: EventListener = (event: Event) => {
    const element = document.getElementById("page-content");
    if (element) {
      const scrollTop = element.scrollTop;
      const headerProfile = document.getElementById("header-profile-page");
      if (scrollTop > 140) {
        headerProfile?.classList.add("change-background");
      } else {
        headerProfile?.classList.remove("change-background");
      }
    }
  };
  useEffect(() => {
    const element = document.getElementById("page-content");
    if (width <= maxWidthDevice.MD) {
      if (element) {
        element.addEventListener("scroll", handleScroll);
      }
    } else {
      element?.removeEventListener("scroll", handleScroll);
    }
    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [width]);
  return null;
}

export default useChangeHeaderMobile;
