/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactComponent as IconArrowLeft } from "assets/icons/icon-arrow-left.svg";
import { useNavigate } from "react-router-dom";
import { media } from "styles/breakpoints";

interface Props {
  title: string;
}

export const HeaderUser = (props: Props) => {
  const { title } = props;
  const navigate = useNavigate();

  return (
    <div
      className="header-user"
      css={css`
        display: flex;
        align-items: center;

        ${media.md} {
          position: sticky;
          top: 0;
          background-color: #050623;
          z-index: 8;
          padding: 16px;
        }
      `}
    >
      <IconArrowLeft
        onClick={() => navigate(-1)}
        fill="white"
        css={css`
          cursor: pointer;
          width: 24px;
          height: 24px;
          ${media.md} {
            width: 16px;
            height: 16px;
          }
        `}
      />
      <p
        css={css`
          font-size: 20px;
          font-weight: 300;
          line-height: 30px;
          margin-left: 12px;

          ${media.md} {
            font-size: 15px;
          }
        `}
      >
        {title}
      </p>
    </div>
  );
};
