/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as AnimalContest } from "assets/icons/menus/icon-animal_contest.svg";
import { ReactComponent as Blockchain } from "assets/icons/menus/icon-blockchain.svg";
import { ReactComponent as BoardGame } from "assets/icons/menus/icon-board-game.svg";
import { ReactComponent as CasioSvg } from "assets/icons/menus/icon-casino-home.svg";
import { ReactComponent as CollectSvg } from "assets/icons/menus/icon-collect.svg";
import { ReactComponent as IconDownload } from "assets/icons/menus/icon-download.svg";
import { ReactComponent as Facebook } from "assets/icons/menus/icon-fb.svg";
import { ReactComponent as Fishing } from "assets/icons/menus/icon-fish.svg";
import { ReactComponent as GameSvg } from "assets/icons/menus/icon-game.svg";
// import { ReactComponent as Instagram } from "assets/icons/menus/icon-instagram.svg";
// import { ReactComponent as LineChat } from "assets/icons/menus/icon-line.svg";
import { ReactComponent as Line } from "assets/icons/menus/icon-line.svg";
import { ReactComponent as Live } from "assets/icons/menus/icon-live.svg";
import { ReactComponent as Online } from "assets/icons/menus/icon-online.svg";
import { ReactComponent as FriendSvg } from "assets/icons/menus/icon-profile-share.svg";
import { ReactComponent as PromotionsSvg } from "assets/icons/menus/icon-promotion.svg";
import { ReactComponent as QQ } from "assets/icons/menus/icon-qq.svg";
import { ReactComponent as Service } from "assets/icons/menus/icon-service-sidebar.svg";
import { ReactComponent as Slot } from "assets/icons/menus/icon-slot.svg";
import { ReactComponent as Sport } from "assets/icons/menus/icon-sport.svg";
import { ReactComponent as Telegram } from "assets/icons/menus/icon-telegram.svg";
import { ReactComponent as Wechat } from "assets/icons/menus/icon-wechat.svg";
import { ReactComponent as Whatsapp } from "assets/icons/menus/icon-whatsapp.svg";
import { ReactComponent as Zalo } from "assets/icons/menus/icon-zalo.svg";
import IconLanguage from "assets/icons/menus/imageIconLanguage.png";
import IconLanguageZH from "assets/icons/menus/imageIconLanguage-ChINESE.png";
import IconLanguageVN from "assets/icons/menus/imageIconLanguage-VIETNAM.png";

import PATH from "./path";

export type MenuItem = {
  label: React.ReactNode;
  key: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  className?: string;
  isClick?: boolean;
  isBorder?: boolean;
  isHover?: boolean;
  categoryId?: string;
  providerCode?: string;
  id?: string;
  isLang?: boolean;
};

export const listMenuSideBar: MenuItem[] = [
  {
    label: "",
    key: "menu1",
    children: [
      {
        label: "sidebar:Casino",
        key: PATH.home,
        icon: <CasioSvg />,
        isClick: true,
      },
      {
        label: "sidebar:Collection",
        key: PATH.myCollectionPage,
        icon: <CollectSvg />,
        isClick: true,
      },
      {
        label: "sidebar:Record",
        key: PATH.playRecordPage,
        icon: <GameSvg />,
        isClick: true,
      },
      {
        label: "sidebar:Promotions",
        key: PATH.promotionPage,
        icon: <PromotionsSvg />,
        isClick: true,
      },
      {
        label: "sidebar:Friend",
        key: PATH.profile.friendSharing,
        icon: <FriendSvg />,
        isClick: true,
      },
    ],
  },
  {
    label: "",
    key: "menu2",
    isBorder: true,
    children: [
      {
        label: "sidebar:Live",
        key: "/live",
        icon: <Live />,
        isClick: true,
        isHover: true,
        categoryId: "LC",
      },
      {
        label: "sidebar:Slots",
        key: "/slots",
        icon: <Slot />,
        isClick: true,
        isHover: true,
        categoryId: "SL",
      },
      {
        label: "sidebar:Sports",
        key: "/sports",
        icon: <Sport />,
        isClick: true,
        isHover: true,
        categoryId: "SB",
      },
      {
        label: "sidebar:ESports",
        key: "/e-sport",
        icon: <Blockchain />,
        isHover: true,
        isClick: true,
        categoryId: "ES",
      },
      {
        label: "sidebar:Fishing",
        key: "/fishing",
        icon: <Fishing />,
        isClick: true,
        isHover: true,
        categoryId: "FH",
      },
      {
        label: "sidebar:Crash",
        key: "/crash-game",
        icon: <BoardGame />,
        isClick: true,
        isHover: true,
        categoryId: "BG",
        providerCode: "T1G",
      },
      {
        label: "sidebar:Sabong",
        key: "/sabong",
        icon: <AnimalContest />,
        isClick: true,
        isHover: true,
        categoryId: "CF",
      },
      {
        label: "sidebar:Table",
        key: "/table-card",
        icon: <FriendSvg />,
        isClick: true,
        isHover: true,
        categoryId: "BG",
        providerCode: "JILI",
      },
    ],
  },
  {
    label: "English",
    key: "en",
    isBorder: true,
    isLang: true,
    icon: (
      <img
        src={IconLanguage}
        css={css`
          width: 20px;
          height: 20px;
        `}
        alt=""
      />
    ),
    children: [
      {
        label: "Việt Nam",
        key: "vi",
        isClick: true,
        icon: (
          <img
            src={IconLanguageVN}
            css={css`
              width: 20px;
              height: 20px;
            `}
            alt=""
          />
        ),
      },
      {
        label: "English",
        key: "en",
        isClick: true,
        icon: (
          <img
            src={IconLanguage}
            css={css`
              width: 20px;
              height: 20px;
            `}
            alt=""
          />
        ),
      },
      {
        label: "中文",
        key: "zh",
        isClick: true,
        icon: (
          <img
            src={IconLanguageZH}
            css={css`
              width: 20px;
              height: 20px;
            `}
            alt=""
          />
        ),
      },
    ],
  },
  {
    label: "sidebar:Download",
    key: "",
    icon: <IconDownload />,
    isClick: true,
    isBorder: true,
  },
  {
    label: "sidebar:Contact",
    key: "#Contact",
    icon: <Service />,
    isBorder: true,
    isClick: true,
  },
];

export const ContactUsData = [
  {
    label: "Online",
    key: "#",
    icon: <Online />,
    id: "1",
  },
  {
    label: "Whatsapp",
    key: "#",
    icon: <Whatsapp />,
    id: "2",
  },
  {
    label: "Wechat",
    key: "#",
    icon: <Wechat />,
    id: "3",
  },
  {
    label: "QQ",
    key: "#",
    icon: <QQ />,
    id: "4",
  },
  {
    label: "Line",
    key: "#",
    icon: <Line />,
    id: "5",
  },
  {
    label: "Zalo",
    key: "#",
    icon: <Zalo />,
    id: "6",
  },
  {
    label: "Facebook",
    key: "#",
    icon: <Facebook />,
    id: "7",
  },
  {
    label: "Telegram",
    key: "#",
    icon: <Telegram />,
    id: "8",
  },
];
