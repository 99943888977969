import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const WrapperFormStyle = {
  wrapperContainer: css`
    background-color: ${theme.colors.primary[700]};
    padding: 36px 30px;

    ${media.sm} {
      height: 100vh;
      width: 100%;
      overflow-y: auto;
    }
  `,
  headerCustom: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    h3 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      color: ${theme.colors.white};
    }
    .continue {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: ${theme.colors.primary[100]};
      margin-top: 34.5px;

      &.or {
        margin-top: 22.5px;
      }
    }
    .icon {
      margin-top: 22.5px;
      width: 90px;
      height: 40px;
      background: #ffffff26;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  `,
  closeButton: css`
    width: 40px;
    height: 40px;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    cursor: pointer;
  `,
};
