/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactNode } from "react";

interface Props {
  renderPreviousStep(): ReactNode;
  renderNextStep(): ReactNode;
}

export const StepControl = (props: Props) => {
  const { renderNextStep, renderPreviousStep } = props;
  return (
    <div
      css={css`
        margin-top: 30px;
      `}
    >
      {renderPreviousStep()}
      {renderNextStep()}
    </div>
  );
};
