import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { ResponseCode } from "contants/response";
import { NoInfer } from "react-redux";
import {
  PromotionsListResponseType,
  PromotionsListStateType,
} from "types/promotions";

import { getPromotionsList } from "./promotions.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<PromotionsListStateType>>
) => {
  builder.addCase(
    getPromotionsList.pending,
    (state: PromotionsListStateType) => {
      state.isLoading = true;
    }
  );
  builder.addCase(
    getPromotionsList.fulfilled,
    (
      state: PromotionsListStateType,
      action: PayloadAction<PromotionsListResponseType>
    ) => {
      state.isLoading = false;

      if (action.payload.status !== ResponseCode.SUCCESS) return;

      state.promotionsList = [...action.payload.data];
    }
  );
};
