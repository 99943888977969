import { createSlice } from "@reduxjs/toolkit";
import { ApplicationRootState } from "types";
import { UserStateType } from "types/user";

import { extraReducers } from "./user.extraReducers";

export const initialState: UserStateType = {
  isLoading: false,
  isBalance: false,
  profile: null,
  balance: {
    main_balance: "",
    total: "",
  },
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
  },
  extraReducers,
});

export const selectProfile = (state: ApplicationRootState) =>
  state.user.profile;
export const selectProfileLoading = (state: ApplicationRootState) =>
  state.user.isLoading;
export const selectBalance = (state: ApplicationRootState) =>
  state.user.balance;
export const selectBalanceLoading = (state: ApplicationRootState) =>
  state.user.isBalance;

export const { resetUser } = userSlice.actions;

export default userSlice.reducer;
