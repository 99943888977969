/** @jsxImportSource @emotion/react */

import { Progress } from "antd";
import { ImageAvatarUser } from "contants/config";
import PATH from "contants/path";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUsername } from "store/auth";
import { selectDataMemberVip } from "store/member";

import { RankStatusStyle } from "./style.index";

const RankStatus = () => {
  const { t } = useTranslation("vipDetails");
  const username = useSelector(selectUsername);
  const { data: dataMemberVip } = useSelector(selectDataMemberVip);
  return (
    <>
      <div css={RankStatusStyle.title}>{t("Rank")}</div>
      <div css={RankStatusStyle.wrapperStatus}>
        <div css={RankStatusStyle.box}>
          <div css={RankStatusStyle.boxUser.avatar}>
            <img src={ImageAvatarUser} alt="" />
          </div>
          <div css={RankStatusStyle.boxUser.information}>
            <p>{username}</p>
            <div css={RankStatusStyle.boxUser.currentVip}>
              {t("Current")} <span>VIP</span>
              <span>{dataMemberVip.current_level}</span>
            </div>
            <div css={RankStatusStyle.boxUser.percentage}>
              <Link to={PATH.profile.rebate}>{t("Percent")}</Link>
            </div>
          </div>
        </div>
        <div css={RankStatusStyle.box}>
          <div css={RankStatusStyle.boxVip.header}>
            {t("Next")} <span>VIP</span>
            <span>{dataMemberVip.next_level.level}</span>
          </div>
          <div css={RankStatusStyle.boxVip.progress}>
            <Progress
              percent={Number(dataMemberVip.next_level.turnover_percentage)}
            />
          </div>
          <div css={RankStatusStyle.boxVip.turnover}>
            <div css={RankStatusStyle.currentTurnover}>
              {t("CurrentTurnOver")}:{" "}
              <span>{dataMemberVip.next_level.current_turnover}</span>
            </div>
            <div css={RankStatusStyle.fullTurnover}>
              {t("Target")}: <span>{dataMemberVip.next_level.turnover}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RankStatus;
