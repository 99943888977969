import { createAsyncThunk } from "@reduxjs/toolkit";
import { bettingRecordApi } from "api/bettingrecord";
import { BettingRecordPayload } from "api/bettingrecord/type";

export const getWager = createAsyncThunk(
  "bettingrecord/Wager",
  async (payload: BettingRecordPayload, { rejectWithValue }) => {
    try {
      const dataBettingRecord = await bettingRecordApi.warge(
        payload.username,
        payload
      );
      return dataBettingRecord;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
