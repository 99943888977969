/** @jsxImportSource @emotion/react */

import { Button } from "antd";
import { ReactComponent as NotificationSvg } from "assets/icons/notification.svg";
import LogoHeader from "assets/images/logo-header.png";
import PATH from "contants/path";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectIsAuthenticated } from "store/auth";
import { setOpenModal } from "store/modal";
import { ModalTypeValue } from "types/modal";

import { HeaderStyles } from "./index.style";
import ProfileDropdown from "./ProfileDropdown";
import WalletDropdown from "./WalletDropdown";
import { whiteListHeader } from "./whiteListHeader";

const Header = () => {
  const { t } = useTranslation("header");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isHeader = whiteListHeader.includes(pathname);
  return (
    <div css={HeaderStyles.container(isHeader)}>
      <div css={HeaderStyles.header_left}>
        <Link to={"/"} css={HeaderStyles.logoApp}>
          <img src={LogoHeader} alt="" />
        </Link>
      </div>
      <div css={HeaderStyles.header_right}>
        {/* <div css={HeaderStyles.btnSearch}>
          <SearchSvg fill={theme.colors.white} />
        </div> */}
        {isAuthenticated && (
          <>
            <div>
              <WalletDropdown />
            </div>
            <div css={HeaderStyles.depositButton}>
              <Button onClick={() => navigate(PATH.profile.trading)}>
                <span>{t("Deposit")}</span>
              </Button>
            </div>
            <ProfileDropdown />
            <div
              onClick={() => navigate(PATH.profile.notificationCenter)}
              css={HeaderStyles.btnSearch}
            >
              <NotificationSvg width={24} height={24} />
            </div>
          </>
        )}
        {!isAuthenticated && (
          <>
            <div
              onClick={() => dispatch(setOpenModal(ModalTypeValue.Login))}
              css={HeaderStyles.button(1)}
            >
              <p>{t("Login")}</p>
            </div>
            <div
              onClick={() => dispatch(setOpenModal(ModalTypeValue.Register))}
              css={HeaderStyles.button(2)}
            >
              <p>{t("Register")}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
