import { theme } from "styles/theme";

export const showStatusText = (winlose: number) => {
  switch (true) {
    case winlose > 0:
      return "Win:";
    case winlose < 0:
      return "Lose:";
    default:
      return "Draw:";
  }
};

export const handleColorStatus = (winlose: number) => {
  switch (true) {
    case winlose > 0:
      return "#0477FD";
    case winlose < 0:
      return "#F1212A";
    default:
      return theme.colors.primary[200];
  }
};
