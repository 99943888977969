import { createAsyncThunk } from "@reduxjs/toolkit";
import { retrieveApi } from "api/retrieve";

export const fetchAnnouncement = createAsyncThunk(
  "retrieve/announcement",
  async (
    { page, size }: { page: number; size: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await retrieveApi.retrieveAnnouncement(page, size);
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
