import { sysLang } from "contants/syslang";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientCountryCode, setClientCountryCode } from "store/master";

import { useAppDispatch } from "./app-hook";

// const LangsKey: {
//   [key: string]: string;
// } = {
//   EN: "en",
//   ZH: "zh",
//   VI: "vi",
// };

const languageDefault = localStorage.getItem("i18nLng");

export const useIP = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const dataCountryCode = useSelector(selectClientCountryCode);

  const handleLanguage = useCallback(async () => {
    if (languageDefault) {
      dispatch(setClientCountryCode(languageDefault || "en"));
      i18n.changeLanguage(languageDefault || "en");
      return;
    }

    // const { data } = await axios.get("https://geolocation-db.com/json/");

    // const language = LangsKey[data.country_code as string];

    // if (!language) {
    //   dispatch(setClientCountryCode("en"));
    //   i18n.changeLanguage("en");
    //   return;
    // }
    // dispatch(setClientCountryCode(language));
    // i18n.changeLanguage(language);
    dispatch(setClientCountryCode("en"));
    i18n.changeLanguage("en");
  }, [i18n]);

  // const sysLangCurrent = sysLang.[dataCountryCode];

  const sysLangCurrent = sysLang[dataCountryCode];

  localStorage.setItem("syslang", sysLangCurrent + "");

  useEffect(() => {
    handleLanguage();
  }, [handleLanguage]);
};
