import GX from "assets/images/game/sports/sports_GX.png";
import PIN from "assets/images/game/sports/sports_PIN.png";
import { SubGameType } from "types/game";

export const listGameSports: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "PIN",
    type: "SB",
    featured: "NO",
    new_arrive: "NO",
    picture: PIN,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "IGK",
    type: "SB",
    featured: "NO",
    new_arrive: "NO",
    picture: GX,
  },
];
