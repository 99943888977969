import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthApi } from "api/auth";
import { LoginFormType, RegisterFormType } from "api/auth/type";
import { getProfile } from "store/user/user.thunk";

export const signInWithCredentials = createAsyncThunk(
  "auth/signInWithCredentials",
  async (formData: LoginFormType, { rejectWithValue, dispatch }) => {
    try {
      const response = await AuthApi.login(formData.username, formData);
      const responseData = response.data;
      if (!responseData.status) {
        dispatch(getProfile(formData.username));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerMemberDetail = createAsyncThunk(
  "auth/registerMemberDetail",
  async (formData: RegisterFormType, thunkApi) => {
    try {
      const response = await AuthApi.register(formData.username, formData);
      const responseData = response.data;

      if (!responseData.status) {
        const { dispatch } = thunkApi;
        dispatch(
          signInWithCredentials({
            username: formData.username,
            password: formData.password,
          })
        );
        return responseData;
      } else {
        return thunkApi.rejectWithValue(responseData.msg);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
