import { css } from "@emotion/react";

export const InputCustomStyle = {
  self: css`
    &.ant-input,
    .ant-input {
      width: 100%;
      height: 44px;
      border: 1px solid #3d4668;
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 8px;
      color: #000000;
      outline: none;
      box-shadow: none;
      font-weight: 500;
      transition: 0s all;

      &:focus {
        box-shadow: none !important;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #101520 inset !important;
      -webkit-text-fill-color: #ffffff80 !important;
    }
    input::placeholder {
      color: #ffffff50 !important;
    }
    input {
      color: #ffffff80 !important;
    }

    & .ant-form-item-explain-error {
      color: var(--error-color) !important;
    }
  `,
};
