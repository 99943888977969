/** @jsxImportSource @emotion/react */
import { Tooltip } from "antd";
import { ReactComponent as LeftIcon } from "assets/icons/leftIcon.svg";
import SidebarCategoryGame from "components/ui/SidebarCategoryGame";
import { MenuItem } from "contants/list-menu";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { i18n } from "i18n";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { setClientCountryCode } from "store/master";
import { selectOpenSidebar } from "store/modal";

import Menu from "..";
import { MenuItemViewStyle } from "./index.style";
type Props = {
  item: MenuItem;
  onClick: (val: MenuItem) => void;
  order: number;
  itemKey: string;
  onMouseEnter: (id: string) => void;
  idHover: string;
  isSideBar: boolean;
  index: number;
};

function MenuItemView({
  item,
  onClick,
  order,
  itemKey,
  idHover,
  onMouseEnter,
  isSideBar,
  index,
}: Props) {
  const { t } = useTranslation("sidebar");

  const openSidebar = useAppSelector(selectOpenSidebar);
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useAppDispatch();
  const refChild = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && !openSidebar && item.isLang) {
      setIsOpen(false);
    }
  }, [openSidebar]);

  const onCollapse = (val: boolean) => {
    setIsOpen(val);
    if (val) {
      refChild.current?.scrollIntoView(true);
    }
  };

  const isActive = itemKey === item.key;
  const isFocused = idHover === `${item.key}`;

  const isShow = item.key === "vi" || item.key === "en" || item.key === "zh";

  return (
    <>
      {item.isBorder && <div css={MenuItemViewStyle.dash} />}
      <div css={MenuItemViewStyle.itemContainer}>
        {item.label && (
          <Tooltip
            {...(openSidebar || item.isHover ? { zIndex: -1 } : {})}
            placement="rightBottom"
            title={t(item.label as string)}
          >
            <div
              onMouseEnter={() => {
                if (item.isHover) {
                  onMouseEnter(item.key);
                } else {
                  onMouseEnter("");
                }
              }}
            >
              <div
                onClick={() => {
                  if (
                    item.key === "vi" ||
                    item.key === "en" ||
                    item.key === "zh"
                  ) {
                    onCollapse(!isOpen);

                    dispatch(setClientCountryCode(item.key));
                    i18n.changeLanguage(item.key);

                    return;
                  }

                  if (item.isClick) {
                    onClick(item);
                    return;
                  }

                  onCollapse(!isOpen);
                }}
                css={MenuItemViewStyle.itemContent(
                  item.isClick,
                  item.children?.length ? true : false,
                  isFocused
                )}
                className={`item-menu-view ${isActive && "item-menu-active"}`}
              >
                <div className="icon">{item.icon}</div>
                <div className="label">{t(item.label as string)}</div>

                <div className="icon-open">
                  <LeftIcon />
                </div>
              </div>
              {item.isHover && (
                <SidebarCategoryGame
                  isOpen={isFocused}
                  setIsOpen={(val) => onMouseEnter(val ? item.key : "")}
                  idCategory={item}
                />
              )}
            </div>
          </Tooltip>
        )}
        {isOpen && (
          <div css={MenuItemViewStyle.children(isShow, openSidebar)}>
            <div ref={refChild}>
              {item.children?.length && (
                <Menu
                  itemKey={itemKey}
                  items={item.children}
                  onClick={onClick}
                  order={order + 1}
                  idHover={idHover}
                  onMouseEnter={onMouseEnter}
                  isSideBar={isSideBar}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MenuItemView;
