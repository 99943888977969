interface TypeListGameProvider {
  [x: string]: string;
}
export const listGameProvider: TypeListGameProvider = {
  LC: "Live Casino",
  SL: "Slot",
  SB: "Sport",
  FH: "Fishing",
  ES: "E-Sport",
  BG: "Board Game",
  LT: "Lottery",
  KN: "Keno",
  CF: "Cook Fighting",
  OT: "Others",
};
