/** @jsxImportSource @emotion/react */

import { PlayCircleOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { handleLoginGame } from "pages/Game/helpers/handleLoginGame";
import { selectUsername } from "store/auth";
import { SubGameType } from "types/game";

import { CardGameStyle } from "./style.index";

const CardGame = (props: SubGameType) => {
  const { picture, name } = props;

  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  return (
    <div css={CardGameStyle.wrapper}>
      <div
        onClick={() => handleLoginGame(props, username, dispatch)}
        className="overlay"
      >
        <PlayCircleOutlined style={{ fontSize: "36px" }} />
      </div>
      <img src={picture} alt="" />

      <div data-comp="hover-eff" css={CardGameStyle.textBox}>
        <h3>{name}</h3>
      </div>
    </div>
  );
};

export default CardGame;
