/** @jsxImportSource @emotion/react */

import Loading from "components/ui/Loading";
import { useVerify } from "hooks/useVerify";
import { ReactNode } from "react";

import { UserWrapperPageStyle } from "./index.style";

interface Props {
  children: ReactNode;
  hasMaxWidth?: boolean;
}

export const UserWrapperPage = (props: Props) => {
  const { children, hasMaxWidth = false } = props;
  const { isLoading } = useVerify();
  if (isLoading) return <Loading height={500} width={"100%"} center />;
  return (
    <div css={UserWrapperPageStyle.wrapper}>
      <div css={UserWrapperPageStyle.container(hasMaxWidth)}>{children}</div>
    </div>
  );
};
