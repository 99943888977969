import { css, Theme } from "@emotion/react";

export const SelectCustomStyle = {
  self: ({ colors }: Theme) => css`
    & .ant-select {
      width: 100%;

      &-selector {
        height: 50px !important;
        background-color: #101520 !important;
        outline: none !important;
        box-shadow: none !important;
        color: white !important;
        border-color: #3d4668 !important;

        &:hover {
          border-color: #3d4668 !important;
        }
        .ant-select-selection-placeholder {
          color: #67696f;
          display: flex;
          align-items: center;
        }
      }
      &-selection-item {
        color: #8e9094 !important;
        display: flex;
        align-items: center;
      }
      &-arrow {
        color: ${colors.white};
      }
      &-clear {
        background-color: unset !important;
        color: ${colors.white} !important;
      }

      // custom validate error
      &-status-error {
        .ant-select-selector {
          border: 1px solid var(--error-color) !important;
          .ant-select-selection-placeholder {
            opacity: 1;
            color: var(--error-color);
          }
        }
        .ant-select-arrow {
          color: var(--error-color);
        }
      }

      &-disabled {
        opacity: 0.5;
      }
    }
  `,
};
