import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { NoInfer } from "react-redux";
import { ApiPaginationResponse } from "types/api";
import { AnnouncementType, RetrieveStateType } from "types/retrieve";

import { fetchAnnouncement } from "./retrieve.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<RetrieveStateType>>
) => {
  builder.addCase(fetchAnnouncement.pending, (state: RetrieveStateType) => {
    state.isAnnouncementLoading = true;
  });
  builder.addCase(
    fetchAnnouncement.fulfilled,
    (
      state: RetrieveStateType,
      action: PayloadAction<
        ApiPaginationResponse<AnnouncementType[]> | undefined
      >
    ) => {
      state.isAnnouncementLoading = false;
      if (action.payload) {
        state.announcements = action.payload.data || null;
        state.totalAnnouncements = action.payload.totalCount;
      }
    }
  );
};
