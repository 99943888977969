import IconDeposit from "assets/images/profile/icon-profile-deposit.png";
import IconRebate from "assets/images/profile/icon-profile-rebate.png";
import IconWithdrawal from "assets/images/profile/icon-profile-withdraw.png";

import PATH from "./path";

export interface TypeListFunctionBtn {
  name: string;
  image: string;
  link: string;
  key: number;
}

export const listFunctionBtn: TypeListFunctionBtn[] = [
  {
    name: "Deposit",
    image: IconDeposit,
    link: PATH.profile.trading,
    key: 1,
  },
  {
    name: "Withdrawal",
    image: IconWithdrawal,
    link: `${PATH.profile.trading}?type=2`,
    key: 2,
  },
  // {
  //   name: "Wallet",
  //   image: IconWallet,
  //   link: "",
  //   key: 3,
  // },
  {
    name: "Rebate",
    image: IconRebate,
    link: `${PATH.profile.transaction}?type=3`,
    key: 4,
  },
];
