import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { NoInfer } from "react-redux";
import { MemberStateType, MemberVipResponseType } from "types/member";

import { getMemberVip } from "./member.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<MemberStateType>>
) => {
  builder
    .addCase(getMemberVip.pending, (state: MemberStateType) => {
      state.isLoading = true;
    })
    .addCase(
      getMemberVip.fulfilled,
      (
        state: MemberStateType,
        action: PayloadAction<MemberVipResponseType>
      ) => {
        state.isLoading = false;
        state.dataMemberVip.data = { ...action.payload.data };
      }
    );
};
