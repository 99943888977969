import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const BottomSheetCurrencyStyle = {
  overlay: (visible: boolean) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1098;
    background-color: #00000073;
    display: ${visible ? "block" : "none"};
  `,
  index: (visible: boolean) => css`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: ${theme.colors.primary[700]};
    z-index: 1099;
    max-height: 50vh;
    min-height: 150px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    transition: 0.5s all;
    transform: ${visible ? "translateY(0px)" : "translateY(50vh)"};
  `,
  header: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid ${theme.colors.primary[600]};
    span {
      color: ${theme.colors.primary[300]};
    }
    .close {
      width: 48px;
      text-align: right;
    }
    svg {
      transform: rotate(270deg);
    }
  `,
  listCurrency: css`
    height: 100%;
    overflow: auto;
  `,
  itemCurrency: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;

    .name {
      margin-left: 12px;
      flex: 1;
      color: ${theme.colors.secondary[900]};
    }

    .amount {
      color: ${theme.colors.secondary[900]};
    }
  `,
};
