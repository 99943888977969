import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const UserWrapperPageStyle = {
  wrapper: () => css`
    width: 100%;
    padding: 32px 64px;

    ${media.md} {
      padding: 0;
    }
  `,

  container: (maxWidth: boolean) => css`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: ${maxWidth ? "unset" : "910px"};
  `,
};
