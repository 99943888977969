import { createAsyncThunk } from "@reduxjs/toolkit";
import { GameApi } from "api/game";
import { SubGamePayLoad } from "api/game/type";

export const getSubGameList = createAsyncThunk(
  "game/getSubGameList",
  async (dataParameter: SubGamePayLoad, { rejectWithValue }) => {
    try {
      const response = await GameApi.getSubGame(dataParameter);

      return { ...response.data, ...dataParameter };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGameList = createAsyncThunk(
  "game/getGameList",
  async (game_type: string, { rejectWithValue }) => {
    try {
      const response = await GameApi.getGameList(game_type);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
