import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { BettingRecordPayload, BettingRecordResponseType } from "./type";

export const bettingRecordApi = {
  async warge(username: string, payload: BettingRecordPayload) {
    const response = await axiosRequest.post<BettingRecordResponseType>(
      "",
      payloadHelper("Wager", username, payload),
      {
        cancelToken: payload.source?.token,
      }
    );
    return response.data;
  },
};
