import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const NotificationItemStyle = {
  notificationItem: {
    index: css`
      user-select: none;
      cursor: pointer;

      background: linear-gradient(
        180deg,
        #242f56 0%,
        rgba(8, 24, 80, 0) 99.99%,
        rgba(36, 47, 86, 0) 100%
      );
      padding: 15px;
      border: 1.5px solid ${theme.colors.secondary[900]};
      transition: 0.25s ease;
      -webkit-mask: conic-gradient(
            from 180deg at top 8px right 8px,
            #0000 90deg,
            #000 0
          )
          var(--_i, 200%) 0 /200% var(--_i, 8px) border-box no-repeat,
        conic-gradient(at bottom 8px left 8px, #0000 90deg, #000 0) 0
          var(--_i, 200%) / var(--_i, 8px) 200% border-box no-repeat,
        linear-gradient(#000 0 0) padding-box no-repeat;
      transition: 0.25s, -webkit-mask-position 0.25s 0.25s;
      &:hover {
        --_i: 100%;
        transition: 0.25s, -webkit-mask-size 0.25s 0.25s;
      }
    `,
    header: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,
    bottom: css`
      text-align: left;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;

      margin-left: 12px;
    `,
  },
  headerTitle: css`
    color: ${theme.colors.secondary[900]};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  `,
  headerDate: css`
    font-size: 12px;
    text-decoration: underline;
  `,
};
