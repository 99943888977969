/** @jsxImportSource @emotion/react */

import { CloseOutlined } from "@ant-design/icons";
// import { css } from "@emotion/react";
// import { message } from "antd";
// import FacebookIcon from "assets/icons/facebookIcon.png";
// import { ResponseCode, ResponseText } from "contants/response";
import { useAppDispatch } from "hooks/app-hook";
import React from "react";
// import { IResolveParams, LoginSocialFacebook } from "reactjs-social-login";
// import {
//   registerMemberDetail,
//   signInWithCredentials,
// } from "store/auth/auth.thunk";
import { setOpenModal } from "store/modal";
// import { ApiResponse } from "types/api";
import { ModalTypeValue } from "types/modal";

import { WrapperFormStyle } from "./index.style";
interface Props {
  body?: React.ReactElement;
  footer?: React.ReactElement;
  formType?: ModalTypeValue;
  title?: string | React.ReactElement;
  isBank?: boolean;
}
function WrapperForm({ body, title, footer, isBank }: Props) {
  const dispatch = useAppDispatch();

  // const RegexSpecialCharacters = /[~`!@#$%^&*()+={}[\];:'"<>.,/\\?-_]/g;

  // const handleSocialByEmail = async (email: string) => {
  //   const subEmail = email.substring(0, 15).replace(RegexSpecialCharacters, "");

  //   message.loading({
  //     key: "LoginSocial",
  //     content: "Processing socials login, please wait a moment!",
  //   });

  //   const response = await dispatch(
  //     signInWithCredentials({
  //       username: subEmail,
  //       channel: 1,
  //       email: email,
  //       password: subEmail,
  //     })
  //   );

  //   const { msg, status } = response.payload as ApiResponse;

  //   if (status === ResponseCode.SUCCESS) {
  //     message.success({
  //       key: "LoginSocial",
  //       content: "Login Success!",
  //     });
  //     return;
  //   }

  //   if (status !== ResponseCode.statusErrorLogin) return;

  //   if (!msg.includes(ResponseText.MSG_LOGIN_ERROR)) return;

  //   dispatch(
  //     registerMemberDetail({
  //       username: subEmail,
  //       channel: 1,
  //       email: email,
  //       password: subEmail,
  //       confirm_password: subEmail,
  //     })
  //   );

  //   message.success({
  //     key: "LoginSocial",
  //     content: "Login Success!",
  //   });
  // };

  // const handleSocialById = async (id: string) => {
  //   const subId = id.substring(0, 15);

  //   const response = await dispatch(
  //     signInWithCredentials({
  //       username: subId,
  //       channel: 1,
  //       email: `${subId}@gmail.com`,
  //       password: subId,
  //     })
  //   );

  //   const { msg, status } = response.payload as ApiResponse;

  //   if (status === ResponseCode.SUCCESS) {
  //     message.success({
  //       key: "LoginSocial",
  //       content: "Login Success!",
  //     });
  //     return;
  //   }

  //   if (status !== ResponseCode.statusErrorLogin) return;

  //   if (!msg.includes(ResponseText.MSG_LOGIN_ERROR)) return;

  //   dispatch(
  //     registerMemberDetail({
  //       username: subId,
  //       channel: 1,
  //       email: `${subId}@gmail.com`,
  //       password: subId,
  //       confirm_password: subId,
  //     })
  //   );

  //   message.success({
  //     key: "LoginSocial",
  //     content: "Login Success!",
  //   });
  // };

  // const handleLoginFacebook = async (response: IResolveParams) => {
  //   const { email, userID } = response.data as {
  //     email: string;
  //     userID: string;
  //   };

  //   if (email) {
  //     handleSocialByEmail(email);
  //     dispatch(setOpenModal(ModalTypeValue.Close));
  //     return;
  //   }

  //   handleSocialById(userID);
  // };

  return (
    <div css={WrapperFormStyle.wrapperContainer}>
      <div css={WrapperFormStyle.headerCustom}>
        <h3>{title}</h3>
        {/* {!isBank && (
          <>
            <span className="continue">continue with</span>

            <LoginSocialFacebook
              appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
              onResolve={(response) => handleLoginFacebook(response)}
              onReject={() =>
                message.error({
                  key: "LoginSocial",
                  content: "Please try again later!",
                })
              }
            >
              <div
                className="icon"
                css={css`
                  cursor: pointer;
                `}
              >
                <img src={FacebookIcon} alt="" />
              </div>
            </LoginSocialFacebook>

            <span className="continue or">or</span>
          </>
        )} */}
        <div
          onClick={() => dispatch(setOpenModal(ModalTypeValue.Close))}
          css={WrapperFormStyle.closeButton}
        >
          <CloseOutlined style={{ fontSize: 18 }} />
        </div>
      </div>
      {body}
      {footer}
    </div>
  );
}

export default WrapperForm;
