/** @jsxImportSource @emotion/react */

import React, { useEffect, useImperativeHandle, useRef, useState } from "react";

import { PopupCustomStyle } from "./index.style";

type Props = {
  buttonContent: React.ReactNode;
  popupContent: React.ReactNode;
  top?: number | string;
};
export type RefTypePopup = {
  onToggle: () => void;
};
const PopupCustom = React.forwardRef<RefTypePopup, Props>((props, ref) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        onToggle: () => setActive((prev) => !prev),
      };
    },
    []
  );

  useEffect(() => {
    if (active) {
      const handleClickOutside = (event: MouseEvent) => {
        const target = event?.target as Node;
        if (
          !dropdownRef?.current?.contains(target) &&
          !buttonRef?.current?.contains(target)
        ) {
          setActive(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [active]);
  return (
    <div css={PopupCustomStyle.container}>
      <div ref={buttonRef}>{props.buttonContent}</div>
      <div ref={dropdownRef} css={PopupCustomStyle.popup(active, props.top)}>
        {props.popupContent}
      </div>
    </div>
  );
});

export default PopupCustom;
