import { AuthApi } from "api/auth";
import { ResponseCode } from "contants/response";
import { handleResetData } from "helpers/handleResetData";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUsername } from "store/auth";
import { setOpenModal } from "store/modal";
import { ModalTypeValue } from "types/modal";

import { useAppDispatch, useAppSelector } from "./app-hook";

export const useVerify = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const username =
    useAppSelector(selectUsername) || localStorage.getItem("username");
  const token = useAppSelector(selectToken) || localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchVerifyToken = useCallback(async () => {
    if (!username || !token) {
      setTimeout(() => {
        navigate("/");
        dispatch(setOpenModal(ModalTypeValue.Login));
      }, 500);
      return;
    }
    const response = await AuthApi.verifyToken(username, { username, token });
    setIsLoading(false);
    if (response.status === ResponseCode.SUCCESS) return;
    handleResetData(dispatch, navigate);
  }, [username]);

  useEffect(() => {
    fetchVerifyToken();
  }, [fetchVerifyToken]);

  return { isLoading };
};
