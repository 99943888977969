import BNG from "assets/images/game/Slot/slots_BNG.png";
import CQ9 from "assets/images/game/Slot/slots_CQ9.png";
import DGG from "assets/images/game/Slot/slots_DGG.png";
import FC from "assets/images/game/Slot/slots_FC.png";
import FTG from "assets/images/game/Slot/slots_FTG.png";
import HB from "assets/images/game/Slot/slots_HB.png";
import JDB from "assets/images/game/Slot/slots_JDB.png";
import JILI from "assets/images/game/Slot/slots_JILI.png";
import PGS2 from "assets/images/game/Slot/slots_PGS2.png";
import RTG from "assets/images/game/Slot/slots_RTG.png";
import { SubGameType } from "types/game";

export const listGameSlots: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "PGS2",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: PGS2,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "JDB",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: JDB,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "CQ9",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: CQ9,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "JILI",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: JILI,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "BNG",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: BNG,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "DGG",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: DGG,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "FTG",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: FTG,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "FC",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: FC,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "HB",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: HB,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "RTG",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: RTG,
  },
];
