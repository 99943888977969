/** @jsxImportSource @emotion/react */

import { SkeletonCustomStyle } from "./style.index";

export interface SkeletonProps {
  height: string;
}
const SkeletonCustom = ({ height }: SkeletonProps) => {
  return <div css={SkeletonCustomStyle.index({ height: height })} />;
};

export default SkeletonCustom;
