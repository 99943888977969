/** @jsxImportSource @emotion/react */
import { message } from "antd";
import { ReactComponent as IconCopy } from "assets/icons/icon-copy.svg";
import LogoHeader from "assets/images/logo-header.png";
import ImageAnimeChar from "assets/images/profile/anime-char.png";
import ImageCoverImage from "assets/images/profile/image-cover.png";
import ButtonFunction from "components/ui/ButtonFunction";
import CurrencyItemProfile from "components/ui/CurrencyItemProfile";
import HelmetCustom from "components/ui/Helmet";
import Loading from "components/ui/Loading";
import SideBarProfile from "components/ui/SideBarProfile";
import { ArrowNextIcon, ImageAvatarUser } from "contants/config";
import { FUNCTION_LIST, TypeFunctionList } from "contants/function-list";
import { listFunctionBtn } from "contants/list-btn-function";
import { LIST_CURRENCY } from "dummy/list-currency";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useVerify } from "hooks/useVerify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setOpenModal } from "store/modal";
import { selectProfile } from "store/user";
import { ModalTypeValue } from "types/modal";

import { ProfileInfoStyle } from "./style.index";
import useChangeHeaderMobile from "./useChangeHeaderMobile";

const ProfileInfoPage = () => {
  const { t } = useTranslation(["profile"]);
  const { isLoading } = useVerify();
  const dispatch = useAppDispatch();
  const dataProfile = useAppSelector(selectProfile);
  const navigate = useNavigate();
  useChangeHeaderMobile();

  const [messageApi, contextHolder] = message.useMessage();

  const copyToClipboard = (txt: string) => {
    navigator.clipboard.writeText(txt);
    messageApi.success("Copy to clipboard success!");
  };

  if (isLoading) return <Loading height={500} width={"100%"} center />;
  return (
    <div css={ProfileInfoStyle.index}>
      {contextHolder}
      <HelmetCustom title="Profile" />
      <div id="header-profile-page" css={ProfileInfoStyle.tabName}>
        <div className="avatar">
          <img src={LogoHeader} alt="" />
        </div>
        <div className="titlePage">{t("profile:Title")}</div>
      </div>
      <div css={ProfileInfoStyle.wrapper}>
        <div css={ProfileInfoStyle.mainContent}>
          <div css={ProfileInfoStyle.header.index}>
            <div css={ProfileInfoStyle.header.coverImage}>
              <img src={ImageCoverImage} alt="" />
            </div>
            <div css={ProfileInfoStyle.header.bottom}>
              <div css={ProfileInfoStyle.header.contentBottom.index}>
                <div css={ProfileInfoStyle.header.contentBottom.avt}>
                  <img src={ImageAvatarUser} alt="" />
                </div>
                <div css={ProfileInfoStyle.header.contentBottom.information}>
                  <span>{dataProfile?.username}</span>
                  <IconCopy
                    onClick={() => copyToClipboard(dataProfile?.username || "")}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
            <img
              css={ProfileInfoStyle.header.animeChar}
              src={ImageAnimeChar}
              alt=""
            />
          </div>
          <div css={ProfileInfoStyle.bottom.index}>
            <div css={ProfileInfoStyle.bottom.accountBalance}>
              <div css={ProfileInfoStyle.bottom.title}>
                <span>{t("profile:Balance")}</span>
              </div>
              {LIST_CURRENCY.map((itemCurrency, index) => (
                <CurrencyItemProfile key={index} {...itemCurrency} />
              ))}
            </div>
            <div css={ProfileInfoStyle.bottom.menuUser}>
              <div css={ProfileInfoStyle.bottom.title}>
                <span>{t("profile:Frequently")}</span>
              </div>
              <div css={ProfileInfoStyle.bottom.listFunctionBtn}>
                {listFunctionBtn.map((itemBtn) => (
                  <ButtonFunction {...itemBtn} key={itemBtn.key} />
                ))}
              </div>
              <div css={ProfileInfoStyle.bottom.title}>
                {t("profile:FuncList")}
              </div>
              <div css={ProfileInfoStyle.contentFunction.index}>
                {FUNCTION_LIST.map((data: TypeFunctionList) => (
                  <div
                    key={data.key}
                    css={ProfileInfoStyle.contentFunction.functionItem}
                  >
                    <div css={ProfileInfoStyle.contentFunction.header}>
                      {t(`profile:${data.title}`)}
                    </div>
                    <div css={ProfileInfoStyle.contentFunction.bottom}>
                      {data.content.map((itemContent, index) => (
                        <div
                          key={index}
                          css={ProfileInfoStyle.contentFunction.wrapperItem}
                          onClick={() => {
                            if (itemContent.id === "change-password") {
                              dispatch(
                                setOpenModal(ModalTypeValue.ChangePassword)
                              );
                              return;
                            }
                            navigate(`${itemContent.id}`);
                          }}
                        >
                          <div css={ProfileInfoStyle.contentFunction.funcItem}>
                            <itemContent.image />
                            <span>{t(`profile:${itemContent.name}`)}</span>
                          </div>
                          <ArrowNextIcon className="arrow-svg" />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <SideBarProfile />
      </div>
    </div>
  );
};

export default ProfileInfoPage;
