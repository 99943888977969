import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserApi } from "api/user";
import { ResponseCode } from "contants/response";
import { updateAuthInformation } from "store/auth";
import { MemberBalancePayload } from "types/user";

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (username: string, thunkApi) => {
    try {
      const response = await UserApi.getProfile(username, { username });

      if (response.status !== ResponseCode.SUCCESS) return;

      const { dispatch } = thunkApi;
      dispatch(updateAuthInformation());

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getMemberBalance = createAsyncThunk(
  "user/getMemberBalance",
  async (data: MemberBalancePayload, { rejectWithValue }) => {
    try {
      const response = await UserApi.getMemberBalance(data.username, data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
