import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const SideBarProfileStyle = {
  sideBar: css`
    grid-column-start: 10;
    grid-column-end: 13;
    position: sticky;

    ${media.md} {
      display: none;
    }
  `,
  sideBarVip: {
    index: css`
      border: 1px solid #313c74;
      background: rgba(28, 36, 51, 0.5);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      padding: 15px;
    `,
    header: css`
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      span {
        color: ${theme.colors.secondary[900]};
      }
    `,
    process: css`
      margin-top: 10px;
      margin-bottom: 15px;

      display: flex;
      justify-content: center;
      align-items: center;

      .ant-progress-circle {
        .ant-progress-circle-path {
          stroke: #f85d5d !important;
        }
      }

      .ant-progress-text {
        color: white !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    `,
    textBox: css``,
    dots: css`
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #e91e63;
    `,
    title: css`
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    `,
    describe: css`
      p {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        ${media.lg} {
          font-size: 12px;
          line-height: 16px;
        }
      }
      span {
        color: ${theme.colors.secondary[900]};
      }
    `,
  },
  sideBarRecord: {
    index: css`
      border: 1px solid #313c74;
      border-top: 2px solid #313c74;
      background: rgba(28, 36, 51, 0.5);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      padding: 15px 15px 306px 15px;
      ${media.lg} {
        padding: 15px 15px 206px 15px;
      }
    `,
    header: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `,
    content: css`
      display: flex;
      align-items: center;
      flex-direction: column;

      margin-top: 80px;
    `,
    btn: css`
      button {
        border-radius: 20px;
        border: 1px solid #ffb31a;
        background: #ffb31a;
        width: 134px;
        height: 40px;
        span {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    `,
    notification: css`
      margin-top: 15px;
      margin-bottom: 20px;
      text-align: center;
      span {
        color: #9196af;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    `,
  },
};
