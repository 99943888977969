import { ThemeProvider } from "@emotion/react";
import { notification } from "antd";
import { AuthApi } from "api/auth";
// import { TelegramBox } from "components/ui/TelegramBox";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useIP } from "hooks/useIP";
import { useAppLogout } from "hooks/useLogout";
import { useCallback, useEffect, useRef } from "react";
import { Outlet } from "react-router";
import { selectIsAuthenticated, selectToken, selectUsername } from "store/auth";
import { getListBanner } from "store/banner/banner.thunk";
import { getContactList } from "store/contact/contact.thunk";
import { getMemberBalance, getProfile } from "store/user/user.thunk";
import GlobalStyles from "styles/global";
import { theme } from "styles/theme";
import { outletContextType, StatusNotification } from "types";

function App() {
  useIP();

  const onLogout = useAppLogout();
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = useCallback(
    (status: StatusNotification, message: string) => {
      api[status]({
        message,
        description: "",
      });
    },
    []
  );

  const isAuth = useAppSelector(selectIsAuthenticated);
  const token = useAppSelector(selectToken) || localStorage.getItem("token");
  const username =
    useAppSelector(selectUsername) || localStorage.getItem("username");
  const appRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const appActionRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const checkUserLogin = async () => {
    if (!username) return;

    const isSuccess = await fetchVerifyToken();

    if (!isSuccess) return;

    dispatch(getProfile(username));
    dispatch(getMemberBalance({ username }));
  };

  useEffect(() => {
    dispatch(getListBanner());
    dispatch(getContactList());
  }, []);

  useEffect(() => {
    checkUserLogin();
  }, [username]);

  useEffect(() => {
    if (isAuth) {
      appRef.current = setInterval(fetchVerifyToken, 1000 * 15);
      handleInteraction();
    } else {
      clearInterval(appRef.current);
    }
  }, [isAuth]);

  const fetchVerifyToken = async () => {
    if (!username) return false;
    if (!token) return false;
    try {
      const response = await AuthApi.verifyToken(username, {
        username: username,
        token: token,
      });

      if (response.status !== ResponseCode.SUCCESS) {
        onLogout();
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  };
  const handleInteraction = () => {
    if (appActionRef.current) {
      clearTimeout(appActionRef.current);
    }
    if (!isAuth) return;
    appActionRef.current = setTimeout(() => {
      onLogout();
    }, 1000 * 60 * 10);
  };
  return (
    <div
      onMouseEnter={() => handleInteraction()}
      onKeyDown={() => handleInteraction()}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Outlet context={{ openNotification } as outletContextType} />
        {contextHolder}
        {/* <TelegramBox /> */}
      </ThemeProvider>
    </div>
  );
}

export default App;
