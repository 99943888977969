/** @jsxImportSource @emotion/react */

import moment from "moment";
import { AnnouncementType } from "types/retrieve";

import { NotificationItemStyle } from "./style.index";

const NotificationItem = (props: AnnouncementType) => {
  const { content, start, title } = props;
  return (
    <div css={NotificationItemStyle.notificationItem.index}>
      <div css={NotificationItemStyle.notificationItem.header}>
        <div
          css={NotificationItemStyle.headerTitle}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div css={NotificationItemStyle.headerDate}>
          {moment(start).format("DD/MM/YYYY")}
        </div>
      </div>
      <div
        css={NotificationItemStyle.notificationItem.bottom}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default NotificationItem;
