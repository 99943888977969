import "dayjs/locale/zh-cn";
import "dayjs/locale/vi";
import "dayjs/locale/es";

import { PickerLocale } from "antd/es/date-picker/generatePicker";
import localeEn from "antd/es/date-picker/locale/en_US";
import localeVi from "antd/es/date-picker/locale/vi_VN";
import localeZh from "antd/es/date-picker/locale/zh_CN";
import { Locale } from "antd/lib/locale";
import enUS from "antd/lib/locale/en_US"; // Hoặc sử dụng 'vi' nếu tùy chọn này hỗ trợ
import viVN from "antd/lib/locale/vi_VN"; // Hoặc sử dụng 'vi' nếu tùy chọn này hỗ trợ
import zhCN from "antd/lib/locale/zh_CN"; // Hoặc sử dụng 'vi' nếu tùy chọn này hỗ trợ

interface LocaleType {
  [x: string]: {
    lang: Locale;
  };
}

interface LocaleDateType {
  [x: string]: {
    lang: PickerLocale;
  };
}

export const utilHandleLocale = (locale: string) => {
  const localeObj: LocaleType = {
    en: {
      lang: enUS,
    },
    zh: {
      lang: zhCN,
    },
    vi: {
      lang: viVN,
    },
  };

  return localeObj[locale]?.lang;
};

export const utilHandleCodeDate = (locale: string) => {
  const localeObj: LocaleDateType = {
    en: {
      lang: localeEn,
    },
    zh: {
      lang: localeZh,
    },
    vi: {
      lang: localeVi,
    },
  };
  return localeObj[locale]?.lang;
};
