import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const SearchStyle = {
  wrapper: css`
    display: inline-flex;
    margin: 24px 0;
    ${media.md} {
      padding: 16px;
      margin: 0;
    }
  `,
  form: css`
    display: flex;
    align-items: center;
    gap: 10px;

    padding: 13px 34px 13px 16px;

    border-radius: 10px;
    border: 1px solid #3d4668;
    background: #101520;
    ${media.md} {
      width: 100%;
    }

    input {
      border: none;
      outline: 0px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background-color: inherit;

      &:focus {
        outline: 0px;
      }
    }
  `,
};
