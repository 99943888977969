import { Theme } from "@emotion/react";

export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    neutrals: {
      50: "#F5F5F9",
      100: "#E5E5E6",
      200: "#CACACE",
      250: "#d1ccd9",
      300: "#AFAFB5",
      400: "#95959D",
      500: "#7B7B84",
      600: "#636369",
      700: "#4A4A4F",
      800: "#313135",
      900: "#1C1C1E",
    },
    primary: {
      50: "#a6a8cc",
      100: "#9196AF",
      200: "#c3b2e5",
      300: "#8385ac",
      500: "#363861",
      600: "#3d4668",
      650: "#1d2841",
      700: "#191b44",
      800: "#101502",
      900: "#050623",
      1000: "#2699FB",
    },
    secondary: {
      50: "#f9e9cb",
      300: "#f9e2b3",
      500: "#f9d389",
      700: "#f4c159",
      900: "#ffb31a",
    },
    gradient: {
      1: "linear-gradient(261.81deg, #050623 0%, #A6A8CC 100%)",
      2: "linear-gradient(261.81deg, #5C69FF 0%, #801EFB 100%)",
      3: "linear-gradient(261.81deg, #FFB31A 0%, #F9E9CB 100%)",
    },
    rgb: {
      10: "rgba(255, 255, 255, 0.15)",
      20: "rgba(255, 255, 255, 0.2)",
      30: "rgba(61, 70, 104, 0.3)",
      60: "rgba(61, 70, 104, 0.6)",
    },
    categoryGame: {
      live: "rgb(55, 0, 138)",
      slot: "rgb(225, 100, 0)",
      blockchain: "rgb(104, 133, 247)",
      sport: "rgb(0, 195, 121)",
      fishing: "rgb(25, 67, 176)",
    },
    quanlity: {
      green: "rgb(56 197 34)",
      red: "#F1212A",
      blue: "#0477FD",
    },
  },
  fontSize: {
    11: "11px",
    12: "12px",
    13: "13px",
    14: "14px",
    15: "15px",
    16: "16px",
    17: "17px",
    18: "18px",
    19: "19px",
    20: "20px",
    21: "21px",
    22: "22px",
    23: "23px",
    24: "24px",
    25: "25px",
    26: "26px",
    27: "27px",
    28: "28px",
    29: "29px",
    30: "30px",
    31: "31px",
    32: "32px",
    36: "36px",
    40: "40px",
    48: "48px",
    64: "64px",
    96: "96px",
  },
} as Theme;
