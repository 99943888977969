import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContactApi } from "api/contact";

export const getContactList = createAsyncThunk(
  "contact/getContactList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ContactApi.getContact();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
