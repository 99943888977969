import { css } from "@emotion/react";

export const ChangePasswordModalStyle = {
  wrapper: css`
    .ant-modal {
      &-content {
        background-color: #1d2841;
        border: 1.5px solid #313c74;
        padding: unset;
        button {
          top: 12px;
        }
      }
      &-header {
        background-color: #1d2841;

        padding-left: 1.875rem;
        padding-right: 1.875rem;
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;

        border-bottom: 1.5px solid #313c74;
        .ant-modal-title {
          color: #9196af;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          word-wrap: break-word;
        }
      }
      &-close-x {
        svg {
          fill: white;
          font-size: 18px;
        }
      }
      &-body {
        padding: 1.5625rem 1.875rem 1.875rem;
      }
    }
  `,
  header: css``,
  formWrapper: css`
    form {
      margin-top: 0.625rem;
      label {
        color: #9196af;
        font-size: 16px;
        font-weight: 500;
        line-height: 25.144px;
      }
      .ant-form-item {
        padding-top: 8px;
      }
      button {
        width: 100%;
        background-color: #ffb31a;
        height: 40px;
        border-radius: 20px;
        span {
          color: black;
        }
      }
    }
  `,
  describeFrom: css`
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
  `,
};
