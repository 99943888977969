/** @jsxImportSource @emotion/react */
import { ReactComponent as TransactionSvg } from "assets/icons/icon-transaction.svg";
import { ReactComponent as WalletSvg } from "assets/icons/icon-withdraw.svg";
import { ReactComponent as ProfileSvg } from "assets/icons/profile.svg";
import DefaultAvatar from "assets/images/avatar_default.png";
import { useModalConfirm } from "components/base/ModalConfirm";
import PopupCustom, { RefTypePopup } from "components/ui/Header/PopupCustom";
import { ArrowNextIcon } from "contants/config";
import PATH from "contants/path";
import { useAppSelector } from "hooks/app-hook";
import { useAppLogout } from "hooks/useLogout";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { selectProfile } from "store/user";
import { theme } from "styles/theme";

import { ProfileDropdownStyle } from "./index.style";

const menus = [
  {
    label: "header:Profileinfo",
    icon: ProfileSvg,
    link: PATH.profile.home,
  },
  {
    label: "header:WalletManagement",
    icon: WalletSvg,
    link: PATH.profile.trading,
  },
  {
    label: "header:TransactionHistory",
    icon: TransactionSvg,
    link: PATH.profile.transaction,
  },
];

function ProfileDropdown() {
  const { t } = useTranslation("header");

  const popupRef = useRef<RefTypePopup>(null);
  const profile = useAppSelector(selectProfile);
  const onLogout = useAppLogout();
  const { contextHolder, modalConfig } = useModalConfirm();
  const handleLogout = () => {
    popupRef.current?.onToggle();
    modalConfig({
      title: t("questionLogout"),
      onSuccess: () => {
        onLogout();
      },
    });
  };
  return (
    <>
      <PopupCustom
        ref={popupRef}
        top={"48px"}
        buttonContent={
          <div
            onClick={() => popupRef.current?.onToggle()}
            css={ProfileDropdownStyle.buttonProfile}
          >
            <ProfileSvg fill={theme.colors.white} width={24} height={24} />
          </div>
        }
        popupContent={
          <div css={ProfileDropdownStyle.content}>
            <div css={ProfileDropdownStyle.info}>
              <img src={DefaultAvatar} alt="" />
              <span>{profile?.username}</span>
            </div>
            <div css={ProfileDropdownStyle.listMenu}>
              {menus.map((item, index) => {
                return (
                  <Link
                    className="item-menu"
                    key={`menu-profile-${index}`}
                    to={item.link}
                  >
                    <div className="item-wrap">
                      <item.icon />
                      <span>{t(item.label)}</span>
                    </div>
                    <ArrowNextIcon className="arrow-icon" />
                  </Link>
                );
              })}
            </div>
            <div css={ProfileDropdownStyle.logoutButton}>
              <div onClick={handleLogout} className="buttonLogout">
                <span>{t("Logout")}</span>
              </div>
            </div>
          </div>
        }
      />
      {contextHolder}
    </>
  );
}

export default ProfileDropdown;
