import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";
import { MemberBalancePayload, MemberBalanceResType } from "types/user";

import {
  ChangePasswordPayload,
  GetProfilePayload,
  GetProfileResponse,
  UpdateProfilePayload,
  UpdateProfileResponse,
} from "./type";

export const UserApi = {
  async getProfile(typename: string, payload: GetProfilePayload) {
    const response = await axiosRequest.post<GetProfileResponse>(
      "/",
      payloadHelper("MemberDetail", typename, payload)
    );
    return response.data;
  },

  async getMemberBalance(typename: string, payload: MemberBalancePayload) {
    const response = await axiosRequest.post<MemberBalanceResType>(
      "",
      payloadHelper("GetBalanceDV", typename, payload)
    );

    return response.data;
  },

  async updateProfile(typename: string, payload: UpdateProfilePayload) {
    const { data } = await axiosRequest.post<UpdateProfileResponse>(
      "/",
      payloadHelper("UpdateMemberDetail", typename, payload)
    );
    return data;
  },

  async changePassword(typename: string, payload: ChangePasswordPayload) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ChangePassword", typename, payload)
    );
    return data;
  },
};
