/** @jsxImportSource @emotion/react */
import { ReactComponent as Deposit } from "assets/icons/deposit.svg";
import { ReactComponent as Home } from "assets/icons/home.svg";
import { ReactComponent as Menu } from "assets/icons/menu.svg";
import { ReactComponent as Profile } from "assets/icons/profile.svg";
import { ReactComponent as Share } from "assets/icons/share.svg";
import PATH from "contants/path";
import { useAppDispatch } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { setOpenSidebar } from "store/modal";
import { theme } from "styles/theme";

import { BottomTabStyles } from "./index.style";

const bottomTabValues = [
  {
    name: "Menu",
    icon: (color: string) => <Menu fill={color} />,
    link: "#",
    isMenu: true,
  },
  {
    name: "Share",
    icon: (color: string) => <Share fill={color} />,
    link: PATH.profile.friendSharing,
    isAnimated: true,
  },
  {
    name: "Home",
    icon: (color: string) => <Home fill={color} />,
    link: PATH.home,
  },
  {
    name: "Deposit",
    icon: (color: string) => <Deposit fill={color} />,
    link: PATH.profile.trading,
  },
  {
    name: "Profile",
    icon: (color: string) => <Profile fill={color} />,
    link: PATH.profile.home,
  },
];

const BottomTab = () => {
  const { t } = useTranslation("tab");

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const setIsSidebar = (val: boolean) => {
    dispatch(setOpenSidebar(val));
  };
  return (
    <div css={BottomTabStyles.container}>
      <div css={BottomTabStyles.listTab}>
        {bottomTabValues.map((item, index) => {
          const isActive = item.link === pathname;
          return (
            <Link
              to={item.link}
              onClick={() => {
                if (item.isMenu) {
                  setIsSidebar(true);
                  return;
                }
              }}
              key={`item-bottom-${index}`}
              css={BottomTabStyles.item}
            >
              <div
                css={[
                  BottomTabStyles.icon,
                  item.isAnimated && BottomTabStyles.spinAnimated,
                ]}
              >
                {item.icon(
                  isActive ? theme.colors.secondary[900] : theme.colors.white
                )}
              </div>
              <span css={BottomTabStyles.name(isActive)}>{t(item.name)}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default BottomTab;
