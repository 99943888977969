import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  WithDrawalFormSettingTypeResponse,
  WithdrawalSubMissionForm,
} from "./type";

export const withdrawApi = {
  getWithdrawalForm: (username: string) =>
    axiosRequest.post<WithDrawalFormSettingTypeResponse>(
      "",
      payloadHelper("WithdrawForm", username, { username })
    ),
  withdrawalSubmission: (username: string, payload: WithdrawalSubMissionForm) =>
    axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("Withdrawal", username, payload)
    ),
};
