import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const PopupCustomStyle = {
  container: css`
    position: relative;
  `,
  popup: (active: boolean, top?: number | string) => css`
    position: absolute;
    min-height: 100px;
    background-color: ${theme.colors.primary[500]};
    top: ${top || "0px"};
    right: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 12px;
    display: ${active ? "flex" : "none"};
    z-index: 9;
  `,
};
