import { TypeListCollection } from "components/ui/ListProvider/type";

export const LIST_GAME_TYPE: TypeListCollection[] = [
  {
    name: "All",
    id: "all",
  },
  {
    name: "Blockchain",
    id: "blockchain",
  },
  {
    name: "Slot",
    id: "slot",
  },
  {
    name: "Sport",
    id: "sport",
  },
  {
    name: "E-Sport",
    id: "e-sport",
  },
  {
    name: "Fishing",
    id: "fishing",
  },
  {
    name: "Cock Fighting",
    id: "cock-fighting",
  },
  {
    name: "Board Game",
    id: "board-game",
  },
  {
    name: "Lottery",
    id: "lottery",
  },
];
