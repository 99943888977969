/** @jsxImportSource @emotion/react */

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { ConfigProvider, Empty, Input, Pagination } from "antd";
import HelmetCustom from "components/ui/Helmet";
import ProviderItem from "components/ui/ListProvider/ProviderItem";
import Loading from "components/ui/Loading";
import { allCategoryGame, AllCategoryGameType } from "contants/game";
import { listSort } from "contants/list-hard";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _find from "lodash/find";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { selectDataSubGame, selectIsLoading } from "store/game";
import { getGameList, getSubGameList } from "store/game/game.thunks";
import { theme } from "styles/theme";
import { SubGameType } from "types/game";

import { filterSubGame } from "../helpers/utilFilterSubGame";
import {
  useCategoryGameDetail,
  useProviderDetail,
} from "../hooks/useCategoryDetail";
import { SubGamePageStyle } from "./index.style";
import { SubGameItem } from "./SubGameItem";

const PAGE_SIZE = 48;
interface State {
  search: string;
  idSort: string;
}
const SubGamePage = () => {
  const { categoryName = " ", providerId = "" } = useParams();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const dataSubGame = useAppSelector(selectDataSubGame);
  const isLoading = useAppSelector(selectIsLoading);

  const categoryGameDetail = useCategoryGameDetail(categoryName);
  const providerDetail = useProviderDetail(providerId);

  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const providerIdRef = useRef<string | null>(null);

  const [state, setState] = useState<State>({
    search: "",
    idSort: "all",
  });

  useEffect(() => {
    if (!providerId || isLoading) return;
    let page = pageCurrent;
    if (providerIdRef.current !== providerId) {
      page = 1;
      setPageCurrent(1);
    }
    Promise.all([
      dispatch(
        getSubGameList({
          provider: providerId,
          game_type: categoryGameDetail.id,
          page: page,
          size: PAGE_SIZE,
          featured: state.idSort === "all" ? 0 : 1,
        })
      ),
      dispatch(getGameList(categoryGameDetail.id)),
      (providerIdRef.current = providerId),
    ]);
  }, [providerId, pageCurrent, state.idSort]);

  const renderListSubGame = useCallback(() => {
    if (isLoading) return <Loading height={500} width={"100%"} center={true} />;

    const listFilter = dataSubGame.data.filter((itemFilter: SubGameType) =>
      filterSubGame(itemFilter, state.search)
    );

    if (!dataSubGame.data.length || !listFilter.length)
      return (
        <div
          style={{
            height: "700px",
          }}
        >
          <Empty css={SubGamePageStyle.empty} />
        </div>
      );

    return (
      <div css={SubGamePageStyle.wrapperListSubGame}>
        {listFilter.map((subGame: SubGameType, index: number) => (
          <SubGameItem
            subGame={subGame}
            key={`${subGame.game_code}_${index}`}
          />
        ))}
      </div>
    );
  }, [isLoading, dataSubGame, state.search]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setState((prev) => ({
      ...prev,
      search: e.target.value,
    }));
  };

  const onChangeState = (idSort: string) => {
    setState((prev) => ({
      ...prev,
      idSort,
    }));
  };

  const findCategoryGame = _find(
    allCategoryGame,
    (category: AllCategoryGameType) => {
      return category.id === categoryGameDetail.id;
    }
  );

  return (
    <div css={SubGamePageStyle.wrapper}>
      <div css={SubGamePageStyle.container}>
        <HelmetCustom title={categoryGameDetail.name} />
        <div
          css={css`
            margin-bottom: 12px;
            font-size: 20px;
            font-weight: bold;
          `}
        >
          <span
            css={css`
              margin-bottom: 12px;
              font-size: 20px;
              font-weight: bold;
              text-transform: uppercase;
            `}
          >
            {t(String(findCategoryGame?.label)) || categoryName.toUpperCase()} /{" "}
          </span>
          {providerDetail?.provider_name || ""}
        </div>

        <div css={SubGamePageStyle.inputControl}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#101520",
                colorBorder: "#3D4668",
                colorText: "#FFFF !important",
                colorTextPlaceholder: theme.colors.neutrals[400],
              },
            }}
          >
            <Input
              key={"input-search"}
              onChange={onChange}
              value={state.search}
              placeholder={t("placeholder:Search")}
              suffix={
                !!state.search && (
                  <CloseOutlined
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        search: "",
                      }))
                    }
                  />
                )
              }
              prefix={<SearchOutlined css={SubGamePageStyle.searchIcon} />}
              css={SubGamePageStyle.input}
            />
          </ConfigProvider>
        </div>

        <div css={SubGamePageStyle.blockSort}>
          <ProviderItem data={listSort} onchangeValue={onChangeState} />
        </div>

        {renderListSubGame()}

        {dataSubGame.data.length > 0 && (
          <div css={SubGamePageStyle.boxPagination}>
            <Pagination
              css={SubGamePageStyle.paginationStyle}
              current={pageCurrent}
              pageSize={PAGE_SIZE}
              total={dataSubGame.totalCount}
              onChange={(page: number) => setPageCurrent(page)}
              showSizeChanger={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubGamePage;
