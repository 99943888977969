import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const SidebarCategoryGameStyle = {
  index: (isSidebar: boolean, isOpen: boolean) => css`
    position: fixed;
    top: 0;
    height: 100%;
    width: 320px;
    left: ${!isSidebar ? "84px" : "300px"};
    z-index: 9;
    background-color: rgba(29, 40, 65, 0.8);
    display: ${isOpen ? "flex" : "none"};

    ${media.md} {
      display: none;
    }
  `,
  listGame: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 4px;
      background-color: ${theme.colors.primary[500]};
    }
    ::-webkit-scrollbar-thumb:hover {
      width: 4px;
      background-color: ${theme.colors.primary[500]};
    }
  `,
  itemGame: css`
    width: 100%;
    padding: 12px 16px;
    img {
      width: 100%;
      object-fit: contain;
      cursor: pointer;
      border-radius: 8px;
    }
  `,
  centerView: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-empty-description {
      color: ${theme.colors.white};
    }
  `,
};
