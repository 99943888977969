import { SubGameType } from "types/game";

export function filterSubGame(
  subGame: SubGameType,
  search: string
  // idSort: string
) {
  // if (idSort === "featured" && subGame.featured === "NO") {
  //   return false;
  // }
  // if (idSort === "new_arrive" && subGame.new_arrive === "NO") {
  //   return false;
  // }

  if (subGame.name.toLowerCase().indexOf(search.toLowerCase()) < 0) {
    return false;
  }

  return true;
}
