import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const HeaderMediumStyle = {
  index: css`
    padding: 12px;
    flex: 0;
    display: none;
    ${media.md} {
      display: block;
    }
  `,
  closeBox: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    span {
      width: 40px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 8px 0;
      cursor: pointer;
    }
  `,
  infoAccount: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    .avatar {
      width: 48px;
      height: 48px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .info {
      margin-left: 16px;
      display: flex;
      align-items: center;
      .full-name {
        font-weight: 500;
      }
      .copy {
        margin-left: 12px;
        width: 48px;
      }
    }
  `,
  login: css`
    background-color: ${theme.colors.primary[700]};
    color: ${theme.colors.white};
    width: 64px;
    border: 0;
  `,
  register: css`
    border: 0;
    width: 64px;
    background-color: ${theme.colors.secondary[900]};
    margin-left: 8px;
  `,
  balanceBox: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 8px 0;
    align-items: center;
    border: 1px solid ${theme.colors.primary[600]};
    border-radius: 8px;
    cursor: pointer;

    .inputValue {
      flex: 1;
      display: flex;
      flex-direction: row;
      background-color: ${theme.colors.primary[700]};
      height: 40px;
      align-items: center;
      padding: 0 8px;
      border-bottom-left-radius: 8px;
      border-bottom-left-radius: 8px;

      span {
        flex: 1;
        margin-left: 8px;
      }

      .downIcon {
        transform: rotate(270deg);
        width: 20px;
        height: 20px;
      }
    }

    .sync {
      width: 48px;
      background-color: ${theme.colors.primary[900]};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: ${theme.colors.secondary[900]};
    }
  `,
  groupButton: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    button {
      flex: 1;
      height: 40px;
      border-radius: 24px;

      span {
        font-weight: 500;
      }

      &.deposit {
        margin-right: 6px;
        background-color: ${theme.colors.secondary[900]};
      }
      &.transaction {
        margin-left: 6px;
        border: 1px solid ${theme.colors.secondary[900]};
        background-color: transparent;

        span {
          color: ${theme.colors.secondary[900]};
        }
      }
    }
  `,
};
