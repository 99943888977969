/** @jsxImportSource @emotion/react */
import { Button, Progress } from "antd";
import ImageNoHistory from "assets/images/profile/icon-no-history.png";
import { ArrowNextIcon } from "contants/config";
import { useAppSelector } from "hooks/app-hook";
import { useTranslation } from "react-i18next";
import { selectDataMemberVip } from "store/member";

import { SideBarProfileStyle } from "./style.index";

const SideBarProfile = () => {
  const { t } = useTranslation(["profile", "button"]);
  const { data: dataMemberVip } = useAppSelector(selectDataMemberVip);

  return (
    <div css={SideBarProfileStyle.sideBar}>
      <div css={SideBarProfileStyle.sideBarVip.index}>
        <div css={SideBarProfileStyle.sideBarVip.header}>
          {t("profile:CurrentLevel")} <span>VIP</span>{" "}
          <span>{dataMemberVip.current_level}</span>
        </div>
        <div css={SideBarProfileStyle.sideBarVip.process}>
          <Progress
            type="circle"
            percent={Number(dataMemberVip.next_level.turnover_percentage)}
          />
        </div>
        <div css={SideBarProfileStyle.sideBarVip.textBox}>
          <div css={SideBarProfileStyle.sideBarVip.title}>
            <div css={SideBarProfileStyle.sideBarVip.dots} />
            <span>{t("profile:DepositAmount")}</span>
          </div>
          <div css={SideBarProfileStyle.sideBarVip.describe}>
            <p>
              {t("profile:Remaining")} <span>10.3</span> PESO
            </p>
          </div>
        </div>
      </div>
      <div css={SideBarProfileStyle.sideBarRecord.index}>
        <div css={SideBarProfileStyle.sideBarRecord.header}>
          <span>{t("profile:Record")}</span>
          <ArrowNextIcon />
        </div>
        <div css={SideBarProfileStyle.sideBarRecord.content}>
          <img src={ImageNoHistory} alt="" />
          <div css={SideBarProfileStyle.sideBarRecord.notification}>
            <span>{t("profile:Message")}</span>
          </div>
          <div css={SideBarProfileStyle.sideBarRecord.btn}>
            <Button type="primary">{t("button:GoLobby")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarProfile;
