import LogoCurrency_1 from "assets/images/providers/currency-1.png";
import LogoCurrency_2 from "assets/images/providers/currency-2.png";
import LogoCurrency_3 from "assets/images/providers/currency-3.png";
import LogoCurrency_4 from "assets/images/providers/currency-4.png";
import LogoCurrency_5 from "assets/images/providers/currency-5.png";
import LogoCurrency_6 from "assets/images/providers/currency-6.png";
import LogoCurrency_7 from "assets/images/providers/currency-7.png";
import LogoCurrency_8 from "assets/images/providers/currency-8.png";
import LogoCurrency_9 from "assets/images/providers/currency-9png.png";
import { TypeListCollection } from "components/ui/ListProvider/type";

export const LIST_CURRENCY: TypeListCollection[] = [
  {
    name: "All",
    id: "all",
  },
  {
    name: "ustd",
    id: "ustd",
    image: LogoCurrency_1,
  },
  {
    name: "php",
    id: "php",
    image: LogoCurrency_2,
  },
  {
    name: "thb",
    id: "thb",
    image: LogoCurrency_3,
  },
  {
    name: "cyn",
    id: "cyn",
    image: LogoCurrency_4,
  },
  {
    id: "vnd",
    name: "vnd",
    image: LogoCurrency_5,
  },
  {
    name: "idr",
    id: "idr",
    image: LogoCurrency_6,
  },
  {
    id: "hkd",
    name: "hkd",
    image: LogoCurrency_7,
  },
  {
    name: "brl",
    id: "brl",
    image: LogoCurrency_8,
  },
  {
    id: "inr",
    name: "inr",
    image: LogoCurrency_9,
  },
];
