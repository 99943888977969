import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const ListProviderStyle = {
  wrapper: css`
    padding: 23px 15px;
    background: rgba(16, 21, 32, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    ${media.md} {
      padding: 16px;
    }
  `,
  title: css`
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  `,
  gameType: {
    index: css``,
    list: css`
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    `,
    item: css`
      cursor: pointer;
      span {
        padding: 4px 16px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-decoration: none;

        border-radius: 20px;
        background: rgba(61, 70, 104, 0.3);
      }
      .--active {
        border: 1px solid ${theme.colors.secondary[900]};
        background: rgba(61, 70, 104, 0.1);
        color: ${theme.colors.secondary[900]};
      }
    `,
  },
  providers: {
    index: css``,
    list: css`
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      ${media.md} {
        gap: 5px;
      }
    `,
    item: css`
      cursor: pointer;
    `,
  },
  itemCurrency: css`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    padding: 4px 16px;
    border-radius: 20px;
    background: rgba(61, 70, 104, 0.3);
    transition: 0.25s;
    &:hover {
      background: rgba(61, 70, 104, 0.6);
      transition: 0.25s;
    }
    span {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-transform: uppercase;
    }
  `,
  gameTag: css``,
};
