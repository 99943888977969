import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const VipConditionsStyle = {
  wrapper: css`
    .swiper {
      padding: 150px 30px 50px 30px !important;
      ${media.sm} {
        padding: 100px 20px 30px 20px !important;
      }
    }
  `,
  title: css`
    font-size: 22px;
    font-weight: 600;

    margin-top: 40px;
  `,
  card: css`
    background-color: #12172c;
    border-radius: 12px;

    user-select: none;
    cursor: pointer;
    position: relative;
    transition: all 0.35s ease-in-out;

    &:hover {
      transition: all 0.35s ease-in-out;
      transform: scale(1.08);
      ${media.sm} {
        transform: unset;
      }
    }
  `,

  activeVip: (isActive?: boolean) => css`
    background-color: ${isActive ? "#ffb31a" : "#9196af"};
    height: 15px;
    width: 100%;

    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  `,
  image: css`
    position: absolute;
    top: -50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  textBox: {
    index: css`
      padding-top: 160px;
      padding-bottom: 30px;

      ${media.lg} {
        padding-top: 90px;
      }
      ${media.md} {
        padding-top: 130px;
      }
      ${media.sm} {
        padding-top: 110px;
      }

      display: flex;
      align-items: center;
      flex-direction: column;
    `,
    title: css`
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: uppercase;
    `,
    level: css`
      margin-top: 8px;
      font-weight: 650;
      font-size: 1.25rem;
    `,
  },
};
