import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const DetailNotificationStyle = {
  wrapper: css`
    user-select: none;
    .ant-modal {
      &-content {
        background-color: #1d2841;
        border: 1.5px solid ${theme.colors.primary[300]};
      }
      &-close {
        color: white;
        span {
          font-size: 18px;
        }
      }
      &-header {
        background-color: #1d2841;
        border-bottom: 1.5px solid ${theme.colors.primary[300]};
        .ant-modal-title {
          color: white;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
        }
      }
    }
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  title: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.secondary[900]};
  `,
  date: css`
    font-weight: 300;
    font-size: 11px;
    line-height: 24px;
    color: white;
  `,
  content: css`
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    color: white;
    margin: 10px 0 0 10px;
  `,
  noNotification: css`
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #9196af;
  `,
};
