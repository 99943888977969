import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const MyCollectionPageStyle = {
  wrapper: css`
    padding: 0 64px;
    ${media.sm} {
      padding: 0 12px;
    }
  `,
  result: css`
    text-align: center;
    margin-top: 36px;
    margin-bottom: 11px;
    h3 {
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    span {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  recommendGames: css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
    ${media.lg} {
      grid-template-columns: repeat(3, 1fr);
    }
    ${media.md} {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 16px;
    }

    margin-bottom: 144px;
  `,
};
