/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactComponent as AddWalletIcon } from "assets/icons/icon-add-wallet.svg";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _isEqual from "lodash/isEqual";
import { StepControl } from "pages/TradingPage/components/StepControl";
import { NextButton } from "pages/TradingPage/components/StepControl/NextButton";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setOpenModal } from "store/modal";
import {
  initialState,
  selectDataWithdraw,
  selectDataWithdrawalSubMission,
  setCurrencyId,
  setDefaultWithdrawlSubMission,
  setPlayerBankId,
} from "store/withdraw";
import { PlayerBankType } from "types/bank";
import { ModalTypeValue } from "types/modal";
import { CurrencyType } from "types/withdraw";

import { WalletStepStyle } from "./index.style";

interface Props {
  setStep: Dispatch<SetStateAction<"amount" | "wallet">>;
}

export const WalletStep = (props: Props) => {
  const { setStep } = props;
  const { t } = useTranslation("trading");

  const dispatch = useAppDispatch();

  const dataWithdraw = useAppSelector(selectDataWithdraw);
  const dataWithdrawSubMission = useAppSelector(selectDataWithdrawalSubMission);

  const isEmptyWithdrawSumission = _isEqual(
    initialState.dataWithdrawalSubMission,
    dataWithdrawSubMission
  );

  useEffect(() => {
    if (!isEmptyWithdrawSumission) return;

    dispatch(setDefaultWithdrawlSubMission());
  }, []);

  return (
    <>
      <div
        css={css`
          margin-bottom: 30px;
        `}
      >
        <div css={WalletStepStyle.title}>{t("WithdrawalCurrency")}</div>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 20px;
          `}
        >
          {dataWithdraw.currencies.map((currency: CurrencyType) => (
            <div
              key={currency.currency_id}
              css={css`
                border-radius: 10px;
                background-color: #4e4e4e;
                padding: 11px 16px;
                color: rgba(255, 255, 255, 0.5);
                cursor: pointer;
                border: ${dataWithdrawSubMission.currency_id ===
                currency.currency_id
                  ? "1px solid rgba(255, 179, 26, 0.8)"
                  : "1px solid transparent"};
                transition: all 0.3s ease;

                &:hover {
                  border: 1px solid rgba(255, 179, 26, 0.8);
                }
              `}
              onClick={() => {
                dispatch(setCurrencyId(currency.currency_id));
              }}
            >
              {currency.currency_name}
            </div>
          ))}
        </div>
      </div>

      <div>
        <div css={WalletStepStyle.title}>{t("WithdrawalWallet")}</div>

        <div css={WalletStepStyle.bank.bankList}>
          {dataWithdraw.player_bank.map((player: PlayerBankType) => (
            <div
              key={player.player_bank_id}
              css={WalletStepStyle.bank.bankItem(
                player.player_bank_id,
                dataWithdrawSubMission.player_bank_id
              )}
              onClick={() => {
                dispatch(setPlayerBankId(player.player_bank_id));
              }}
            >
              <span css={WalletStepStyle.bank.bankText}>
                {player.bank_name}
              </span>

              <span css={WalletStepStyle.bank.bankText}>
                {player.bank_account_name}
              </span>

              <span css={WalletStepStyle.bank.bankText}>
                {player.bank_account_no}
              </span>
            </div>
          ))}

          <div
            css={WalletStepStyle.bank.bankAdd}
            onClick={() => dispatch(setOpenModal(ModalTypeValue.Bank))}
          >
            <AddWalletIcon height={24} width={24} />
            <span css={WalletStepStyle.bank.bankTextAdd}>
              {t("button:Addbank")}
            </span>
          </div>
        </div>
      </div>

      <StepControl
        renderPreviousStep={() => <></>}
        renderNextStep={() => (
          <NextButton onNextClick={() => setStep("amount")} />
        )}
      />
    </>
  );
};
