import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { GetProfileResponse } from "api/user/type";
import { NoInfer } from "react-redux";
import { MemberBalanceResType, UserStateType } from "types/user";

import { getMemberBalance, getProfile } from "./user.thunk";

export const extraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<UserStateType>>
) => {
  builder.addCase(getProfile.pending, (state: UserStateType) => {
    state.isLoading = true;
  });
  builder.addCase(
    getProfile.fulfilled,
    (
      state: UserStateType,
      action: PayloadAction<GetProfileResponse | undefined>
    ) => {
      state.isLoading = false;
      state.profile = action.payload?.data || null;
    }
  );
  builder.addCase(getMemberBalance.pending, (state: UserStateType) => {
    state.isBalance = true;
  });
  builder.addCase(
    getMemberBalance.fulfilled,
    (state: UserStateType, action: PayloadAction<MemberBalanceResType>) => {
      state.balance = { ...action.payload.data };
      state.isBalance = false;
    }
  );
};
