import { css } from "@emotion/react";

import { LoadingProps } from ".";

export const LoadingStyle = {
  self: ({ height, center, width }: LoadingProps) => css`
    & .ant-spin {
      ${height &&
      css`
        height: ${typeof height === "number" ? height + "px" : height};
        width: ${width}px;
      `}
      ${center &&
      css`
        display: grid;
        place-items: center;
      `}
    }
  `,
};
